import { useState } from 'react';
import storesApi from 'dataAccess/api/stores.ts';

const locationStorage = () => {
  const [locations, setLocations] = useState([]);

  const getLocations = async () => {
    const storeList = await storesApi.getStores();
    setLocations(storeList);
  };

  return {
    locations,
    getLocations,
  };
};

export default locationStorage;
