import React from 'react';
import { useSelectedOrder } from 'contexts/orders';
import ItemDetails from './ItemDetails';

const OrderItems = () => {
  const selectedOrder = useSelectedOrder();
  const { lineItems } = selectedOrder;

  return (
    <>
      {lineItems.map((item) => (
        <ItemDetails key={item.id} itemDetails={item} selectedOrder={selectedOrder} />
      ))}
    </>
  );
};

export default OrderItems;
