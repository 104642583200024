import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import FullWidth from 'components/layouts/FullWidth';
import ErrorPage from 'views/ErrorPage/ErrorPage';
import Categories from './views/Categories/Categories';
import Products from './views/Products/Products';
import Variants from './views/Variants/Variants';
import PDP from './views/PDP/PDP';

const Shop = () => {
  /**
   * --- Hierarchy ---
   * Category -- "/shop"
   * |__ Products -- "/shop/category/:categoryId"
   * |   |__ Variants -- "/shop/category/:categoryId/product/:productId"
   * |   |   |__ PDP -- "/shop/category/:categoryId/product/:productId/variant/:variantId"
   */
  return (
    <FullWidth>
      <Router basename="/shop" window>
        <Switch>
          <Route
            exact
            path="/category/:categoryId/product/:productId/variant/:variantSku"
            component={PDP}
          />
          <Route exact path="/category/:categoryId/product/:productId" component={Variants} />
          <Route exact path="/category/:categoryId" component={Products} />
          <Route exact path="/" component={Categories} />
          <Route path="*" component={ErrorPage} />
        </Switch>
      </Router>
    </FullWidth>
  );
};

export default Shop;
