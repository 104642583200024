import { useState } from 'react';
import {
  useSetCurrentPage,
  useSetFoundOrderData,
  useOrderInput,
  useSelectedStore,
} from 'contexts/orders';
import OrdersService from 'dataAccess/api/orders.ts';
import LocationHooks from 'utils/LocationHooks';
import storeLocation from 'utils/storeLocation';

const UseOrderSearchFunctions = () => {
  const { useGetStoreNameByKey } = LocationHooks();
  const setCurrentPage = useSetCurrentPage();
  const setFoundOrderData = useSetFoundOrderData();
  const orderInput = useOrderInput();
  const selectedStore = useSelectedStore();
  const [loading, setLoading] = useState(false);
  const [searchType, setSearchType] = useState('');

  const searchTypes = {
    email: 'email',
    orderNumber: 'orderNumber',
    allStoreOrders: 'allStoreOrders',
    todaysStoreOrders: 'todaysStoreOrders',
  };

  const useTodaysStoreOrders = async (pageInput = 1) => {
    setCurrentPage(pageInput);
    const storeKey = storeLocation.getStoreKey();
    const result = await OrdersService.getTodaysStoreOrders(storeKey, pageInput);
    setFoundOrderData(result.orders);
  };

  const useGetByOrderNumber = async (orderNumber) => {
    setCurrentPage(1);
    const result = await OrdersService.getByOrderNumber(orderNumber);
    if (result?.order) {
      setFoundOrderData({ results: [result.order], total: 1 });
    } else {
      setFoundOrderData({ results: [], total: 0 });
    }
  };

  const useGetByEmail = async (email, pageInput = 1) => {
    setCurrentPage(pageInput);
    const result = await OrdersService.getByEmail(email, pageInput);
    setFoundOrderData(result.orders);
  };

  const useGetByStore = async (pageInput = 1) => {
    setCurrentPage(pageInput);
    setSearchType(searchTypes.allStoreOrders);
    const result = await OrdersService.getByStoreKey(selectedStore.key, pageInput);
    setFoundOrderData(result.orders);
    setLoading(false);
  };
  const useSearchUserInput = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (orderInput.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)) {
      setSearchType(searchTypes.email);
      await useGetByEmail(orderInput);
      setLoading(false);
    } else if (orderInput) {
      setSearchType(searchTypes.orderNumber);
      useGetByOrderNumber(orderInput);
      setLoading(false);
    } else {
      setSearchType(searchTypes.allStoreOrders);
      useGetByStore();
      setLoading(false);
    }
  };
  /**
   * This Function calls the MPOS API based on the last search that was performed.
   * @param {number} page - The page of the paginated orders to be accessed by MPOS API
   *
   */
  const useHandlePageChange = (page) => {
    switch (searchType) {
      case searchTypes.email:
        useGetByEmail(orderInput, page + 1);
        break;
      case searchTypes.orderNumber:
        useGetByOrderNumber(orderInput);
        break;
      case searchTypes.allStoreOrders:
        useGetByStore(page + 1);
        break;
      case searchTypes.todaysStoreOrders:
        useTodaysStoreOrders(page + 1);
        break;
      default:
    }
  };
  const getOrderLocation = (order) => {
    let orderLocation = useGetStoreNameByKey(order?.store?.key);
    if (order.custom?.fields?.source === 'web') {
      orderLocation = 'Web';
    }
    return orderLocation;
  };
  /**
   * This function formats the order data so that it can be displayed in a table format.
   * @param {array} orderData - An array of orders returned by the MPOS API.
   * @returns array of rows to be displayed.
   * {id - The identifying field to be displayed in the first column.
   *  columns - Array of the data that is going to be displayed in the order that it is going to be displayed.
   *  data - original order data with extra data that is not going to be displayed.
   * }
   */
  const useFormatOrderTableData = (orderData) => {
    const tableData = orderData.map((order) => {
      const orderLink = `order/${order.orderNumber}`;
      const orderDate = new Date(order.createdAt).toLocaleDateString();
      const customerName = `${order.shippingAddress?.firstName} ${order.shippingAddress?.lastName}`;
      const customerEmail = order.customerEmail;
      const orderLocation = getOrderLocation(order);
      return {
        id: order.orderNumber,
        link: orderLink,
        columns: [orderDate, customerName, customerEmail, orderLocation],
        data: order,
      };
    });
    return tableData;
  };

  return {
    useTodaysStoreOrders,
    useSearchUserInput,
    useHandlePageChange,
    useFormatOrderTableData,
    loading,
  };
};

export default UseOrderSearchFunctions;
