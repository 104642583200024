import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import DescriptionIcon from '@mui/icons-material/Description';
import { copyText } from 'language';
import { useCart } from 'contexts';
import { Box } from '@mui/system';
import emailValidation from 'utils/formValidation';
import { Close } from '@mui/icons-material';
import cartService from 'dataAccess/api/cart.ts';
import TextLoader from 'components/TextLoader';
import ProcessIndicator from 'components/ProcessIndicator/ProcessIndicator';

const SendDraft = () => {
  const cart = useCart();
  const [canSendDraft, setCanSendDraft] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(cart?.customerEmail || '');
  const [note, setNote] = useState('');
  const [status, setStatus] = useState('');
  const [statusIcon, setStatusIcon] = useState(null);

  const handleClose = () => {
    setOpen(false);
    setEmail(cart?.customerEmail || '');
  };

  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;
    if (emailValidation.isValidEmail(inputEmail)) {
      setEmail(inputEmail);
    } else {
      setEmail('');
    }
  };

  const markCartAsDraft = async () => {
    setStatus(copyText.Cart.CartTools.DraftOrder.markingCartAsDraft);
    setLoading(true);
    setStatusIcon(<CircularProgress size={20} />);
    const result = await cartService.markCartAsDraft(cart?.id);
    return result;
  };

  const sendDraftOrderEmail = async () => {
    setStatus(copyText.Cart.CartTools.DraftOrder.sendDraft);
    const draftEmailSent = await cartService.sendDraftOrderEmail(email, note, cart);
    if (draftEmailSent) {
      setStatusIcon(null);
      setStatus(null);
      setOpen(false);
    }
  };

  const handleSend = async () => {
    let markedAsDraft = false;
    try {
      const result = await markCartAsDraft();
      if (result.is_draft_order) {
        markedAsDraft = true;
        await sendDraftOrderEmail();
      } else {
        throw new Error(copyText.Cart.CartTools.DraftOrder.markAsDraftFailure);
      }
      setLoading(false);
    } catch (error) {
      setStatusIcon(<Close />);
      if (markedAsDraft) {
        setStatus(copyText.Cart.CartTools.DraftOrder.sendDraftFailure);
      } else {
        setStatus(copyText.Cart.CartTools.DraftOrder.markAsDraftFailure);
      }
      setLoading(false);
    }
  };
  const checkCart = (_cart) => {
    if (!_cart || _cart?.lineItems?.length === 0) {
      return true;
    }
    return false;
  };

  const populateCanSendDraft = (_cart) => {
    const canSend = checkCart(_cart);
    setCanSendDraft(canSend);
  };

  useEffect(() => {
    populateCanSendDraft(cart);
  }, [cart]);

  return (
    <>
      <MenuItem
        disabled={canSendDraft}
        onClick={() => setOpen(true)}
        data-testid="sendDraft-dialog-button"
      >
        <Icon sx={{ mr: 1 }}>
          <DescriptionIcon />
        </Icon>
        <Typography>{copyText.Cart.CartTools.sendDraft}</Typography>
      </MenuItem>
      <Dialog data-testid="sendDraft-dialog-box" open={open} onClose={handleClose}>
        <Box>
          <DialogTitle
            sx={{ backgroundColor: 'lightGrayBG' }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>{copyText.Cart.CartTools.DraftOrder.sendDraftTitle}</Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <TextField
              label={copyText.Cart.CartTools.email}
              sx={{ mt: 2, mb: 5 }}
              defaultValue={email}
              fullWidth
              placeholder={copyText.Cart.CartTools.customerEmail}
              onChange={handleEmailChange}
            />
            <TextField
              rows={5}
              multiline
              label={copyText.Cart.CartTools.DraftOrder.addNote}
              placeholder={copyText.Cart.CartTools.DraftOrder.addNotePlaceholder}
              fullWidth
              onChange={(e) => setNote(e.target.value)}
            />
            <Alert icon={false} sx={{ p: 3, mt: 3, ml: 1, mr: 1 }}>
              <Typography sx={{ mb: 1 }}>
                {copyText.Cart.CartTools.DraftOrder.advisoryOne}{' '}
              </Typography>
              <Typography>
                <strong>{copyText.Cart.CartTools.DraftOrder.advisoryTwo}</strong>
              </Typography>
            </Alert>
          </DialogContent>
          <DialogActions
            sx={{
              backgroundColor: 'lightGrayBG',
              p: 2,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Grid columns={16} container display="flex" justifyContent="space-between">
              <Grid item xs={0} sx={{ display: { xs: 'none', sm: 'flex' } }} sm={10}>
                <ProcessIndicator statusIcon={statusIcon} text={status} />
              </Grid>
              <Grid item xs={16} sm={6}>
                <Box display="flex" justifyContent="space-between">
                  <Button
                    sx={{ mr: 3, pl: 3, pr: 3 }}
                    data-testid="sendDraft-cancel"
                    onClick={handleClose}
                  >
                    {copyText.App.cancel}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={!email || loading}
                    data-testid="sendDraft-send"
                    onClick={handleSend}
                    sx={{ pl: 3, pr: 3, mr: 2 }}
                  >
                    <TextLoader text={copyText.App.send} size={20} loading={loading} />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default SendDraft;
