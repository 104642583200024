import React from 'react';
import { Icon, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { string } from 'prop-types';

const ProcessIndicator = ({ statusIcon, text }) => {
  return (
    <Box sx={{ p: 1 }} display="flex" alignItems="center">
      <Icon sx={{ mr: 2, fontSize: '30px', display: 'flex', alignItems: 'center' }}>
        {statusIcon}
      </Icon>
      <Typography display="flex" alignItems="center">
        {text}
      </Typography>
    </Box>
  );
};

ProcessIndicator.propTypes = {
  statusIcon: string,
  text: string,
};

ProcessIndicator.defaultProps = {
  statusIcon: null,
  text: null,
};

export default ProcessIndicator;
