import React, { useState, useEffect } from 'react';
import { Grid, Typography, LinearProgress, Box } from '@mui/material';
import convertCentToDollar from 'utils/convertCentToDollar';
import { copyText } from 'language';
import { number, shape } from 'prop-types';

const OrderTotal = ({ cart }) => {
  const [totalPrice, setTotalPrice] = useState('$0.00');
  const [noTotal, setNoTotal] = useState(true);

  const getGiftCards = () => {
    const total = 0;
    let giftCards = 0;
    if (cart?.giftCards) {
      giftCards = cart.giftCards.reduce((acc, next) => {
        return acc + next.amount.centAmount;
      }, total);
    }
    const cartTotal = cart?.taxedPrice?.totalGross?.centAmount;
    if (giftCards > cartTotal) {
      return cartTotal;
    }
    return giftCards;
  };

  const getTotalPrice = () => {
    const giftCards = getGiftCards();
    if (cart?.taxedPrice?.totalGross?.centAmount) {
      setNoTotal(false);
      setTotalPrice(
        convertCentToDollar((cart?.taxedPrice?.totalGross?.centAmount || 0) - giftCards),
      );
    } else {
      setNoTotal(true);
    }
  };
  useEffect(() => {
    getTotalPrice();
  }, [cart]);
  return (
    <Grid container columns={16} sx={{ display: 'flex' }}>
      <Grid item xs={6}>
        <Typography align="left" sx={{ fontWeight: 'bold', fontSize: 14 }}>
          {copyText.Cart.OrderTotal.total}
        </Typography>
      </Grid>
      <Grid item xs={10} alignItems="center" alignContent="center">
        {noTotal && (
          <Box display="flex" justifyContent="flex-end" marginTop={1} marginRight={1}>
            <LinearProgress sx={{ width: '20%' }} />
          </Box>
        )}
        {!noTotal && (
          <Typography align="right" sx={{ fontWeight: 'bold' }}>
            {totalPrice}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

OrderTotal.propTypes = {
  cart: shape({
    giftCards: shape({
      amount: shape({
        centAmount: number.isRequired,
      }).isRequired,
    }).isRequired,
    taxedPrice: shape({
      totalGross: shape({
        centAmount: number.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default OrderTotal;
