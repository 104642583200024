import React from 'react';
import { Typography, Button, Divider, Grid } from '@mui/material';
import { useSelectedOrder } from 'contexts/orders';
import EmailIcon from '@mui/icons-material/Email';
import TextsmsIcon from '@mui/icons-material/Textsms';
import getDate from 'utils/getDate';
import { copyText } from 'language';

const OrderDetails = () => {
  const selectedOrder = useSelectedOrder();
  const getAgent = () => {
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    // This is to make sure only emails are displayed on the front end.
    if (emailRegex.test(selectedOrder.createdBy.externalUserId)) {
      return selectedOrder.createdBy.externalUserId;
    }
    return copyText.Orders.OrderDetails.notAvailable;
  };

  return (
    <>
      <Divider />
      <Grid container backgroundColor="lightGrayBG" justifyContent="space-between" p={1}>
        <Grid item sm={6}>
          <Typography variant="subtitle1">
            {copyText.Orders.OrderDetails.orderDate} {getDate(selectedOrder.createdAt)}
          </Typography>
        </Grid>
        {/* SUNSET At some point we need to implement this and add it back in. */}
        {/* <Grid item sm={4}>
          <Grid container alignItems="center">
            <Grid item>
              <Typography variant="subtitle1" mr={1}>
                {copyText.Orders.OrderDetails.status}
              </Typography>
            </Grid>
            <Grid item>
                TODO: Figure out all the different values that status could have and change color accordingly
              <Chip label={selectedOrder.shipmentState} color="success" />
            </Grid>
          </Grid>
        </Grid> */}
        {/* <Grid item sm={12}>
          <Typography variant="subtitle1" width="100%">
            {copyText.Orders.OrderDetails.received}
          </Typography>
        </Grid> */}
        <Grid item sm={12}>
          <Typography variant="subtitle1" width="100%">
            {copyText.Orders.OrderDetails.salesRep}
            {getAgent()}
          </Typography>
        </Grid>
        <Grid item sm={12} mt={1}>
          <Grid container justifyContent="space-around" alignItems="center">
            <Grid item>
              <Button
                startIcon={<EmailIcon />}
                sx={{
                  boxShadow: '0px 1px 3px ',
                }}
              >
                {copyText.Orders.OrderDetails.contact.email}
              </Button>
            </Grid>
            <Grid item>
              <Button
                startIcon={<TextsmsIcon />}
                sx={{
                  boxShadow: '0px 1px 3px',
                }}
              >
                {copyText.Orders.OrderDetails.contact.text}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default OrderDetails;
