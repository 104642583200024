import { useEffect, useState } from 'react';
import toDollar from 'utils/toDollar';

const calculateDiscounts = (selectedOrder) => {
  const [totalDiscounts, setTotalDiscounts] = useState(0);
  const [discounts, setDiscounts] = useState([]);
  const getDiscounts = () => {
    const discountArray = [];
    selectedOrder.lineItems.map((item) => {
      discountArray.push(...JSON.parse(item.custom.fields.discounts_json));
      return null;
    });
    setDiscounts(discountArray);
    const total = discountArray.reduce((acc, discount) => {
      if (discount.cent_amount) {
        return acc + discount.cent_amount;
      }
      return acc;
    }, 0);
    setTotalDiscounts(toDollar(total));
  };
  useEffect(() => {
    getDiscounts();
  }, [selectedOrder.id]);

  return { discounts, totalDiscounts };
};

export default calculateDiscounts;
