import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Grid,
  Divider,
  List,
  Typography,
  Container,
  CircularProgress,
  IconButton,
  ListItem,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import cartService from 'dataAccess/api/cart.ts';
import { Box } from '@mui/system';
import { Close, Refresh } from '@mui/icons-material';
import { useOldCart, useSetCart, useSetOldCart } from 'contexts';
import TextLoader from 'components/TextLoader';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { loadOldCartShippingMethods, moveToTopOfHistory } from 'utils/cartHistory';
import { copyText } from 'language';
import CartHistoryItem from './components/CartHistoryItem';
import CartHistoryDetails from './components/CartHistoryDetails.jsx/CartHistoryDetails';

const CartHistory = () => {
  const oldCart = useOldCart();
  const setOldCart = useSetOldCart();
  const setCart = useSetCart();
  const [cartSearchQuery, setCartSearchQuery] = useState('');
  const [foundCarts, setFoundCarts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCart, setLoadingCart] = useState(false);
  const [status, setStatus] = useState('');
  const history = useHistory();

  const getSessionCarts = async () => {
    setLoading(true);
    const result = JSON.parse(sessionStorage.getItem('cartHistory'));
    const activeCarts = result.filter((item) => item.status === 'active');
    const cartIdsFilter = activeCarts.map((item) => `"${item.cart}"`).join(',');
    const cartsByIdParams = {
      filter: `id in (${cartIdsFilter})`,
    };
    const cartsById = await cartService.searchCart(cartsByIdParams);
    if (cartsById.results) {
      setFoundCarts(cartsById.results);
    }
    setLoading(false);
  };

  const searchByEmail = async () => {
    try {
      setLoading(true);
      const cartsByIdParams = {
        filter: `customerEmail = "${cartSearchQuery}" and cartState = "Active"`,
      };
      const cartsByEmail = await cartService.searchCart(cartsByIdParams);
      if (cartsByEmail.results) {
        setFoundCarts(cartsByEmail.results);
      }
    } catch (error) {
      setStatus(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSetCart = async () => {
    try {
      setLoadingCart(true);
      await setCart(oldCart);
      loadOldCartShippingMethods(oldCart);
      moveToTopOfHistory(oldCart.id);
      history.push('/cart');
    } catch {
      setLoadingCart(false);
    } finally {
      setLoadingCart(false);
      setOldCart({});
    }
  };

  const handleInput = (event) => {
    setCartSearchQuery(event.target.value);
  };

  useEffect(() => {
    getSessionCarts();
  }, []);

  // SUNSET: Refactor this to use page template after it's finished
  return (
    <Container disableGutters sx={{ width: '100%', mt: 10 }}>
      <Grid container justifyContent="center" alignItems="center" sx={{ p: 1.5 }}>
        <Grid item sm={6}>
          <TextField
            placeholder={copyText.Cart.CartTools.CartHistory.searchByEmail}
            InputProps={{
              endAdornment: (
                <Button
                  disabled={!cartSearchQuery}
                  color="primary"
                  onClick={searchByEmail}
                  variant="contained"
                >
                  {loading ? <CircularProgress color="secondary" size={25} /> : <SearchIcon />}
                </Button>
              ),
            }}
            onChange={handleInput}
            variant="outlined"
            fullWidth
          />
        </Grid>
      </Grid>
      {status && (
        <Grid
          item
          xs={16}
          sx={{ backgroundColor: 'lightAquaBG', height: '2.5rem', p: 5 }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography>
              {copyText.Cart.CartTools.CartHistory.searchFailed}: {status}
            </Typography>
            <Typography>{copyText.Feedback.tryAgain}</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={() => setStatus()}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      )}
      <Divider />
      <Grid container columns={16}>
        <Grid item xs={16} sm={6} display="flex" flexDirection="column">
          <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography component="h4" sx={{ m: 2 }} variant="h5">
              {copyText.Cart.CartTools.history}
            </Typography>
            <IconButton onClick={getSessionCarts}>
              <Refresh color="primary" />
            </IconButton>
          </ListItem>
          <Divider />
          {foundCarts.length === 0 && (
            <Box display="flex" height="50vh" justifyContent="center" alignItems="center">
              {copyText.Cart.CartTools.CartHistory.noCartsFound}
            </Box>
          )}
          <List
            sx={{
              position: 'relative',
              overflow: 'scroll',
              msOverflowStyle: 'none',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              maxHeight: '65vh',
              mb: 20,
              p: 1,
            }}
          >
            {foundCarts.length > 0 &&
              foundCarts.map((cart) => {
                return <CartHistoryItem key={cart.id} cart={cart} />;
              })}
          </List>
        </Grid>
        <Grid item xs={0} sm={0.25} alignItems="center">
          <Divider orientation="vertical" />
        </Grid>
        {oldCart.id ? (
          <Grid item xs={16} sm={9.75}>
            <Grid container alignItems="center">
              <Grid sx={{ p: 1, pt: 2 }} item sm={16} display="flex" justifyContent="space-between">
                <Typography sx={{ ml: 1 }} variant="h5" component="h1">
                  {copyText.Cart.CartTools.CartHistory.cartDetails}
                </Typography>
                <Button
                  disabled={!oldCart || loadingCart}
                  variant="contained"
                  onClick={handleSetCart}
                >
                  <TextLoader loading={loadingCart} text="Load this cart" size={20} />
                </Button>
              </Grid>
            </Grid>
            <Divider />
            <Box
              sx={{
                position: 'relative',
                overflow: 'scroll',
                msOverflowStyle: 'none',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                maxHeight: '65vh',
                mb: 20,
                p: 1,
              }}
            >
              <CartHistoryDetails />
            </Box>
          </Grid>
        ) : (
          <Grid
            item
            xs={16}
            sm={9.75}
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="75vh"
          >
            {copyText.Cart.CartTools.CartHistory.selectCartToSeeDetails}
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default CartHistory;
