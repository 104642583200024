import React, { useEffect } from 'react';
import { Divider, List, ListItem, Typography } from '@mui/material';
import { useSelectedOrder, useOrderPayment, useSetOrderPayment } from 'contexts/orders';
import toDollar from 'utils/toDollar';
import { copyText } from 'language';
import PaymentService from 'dataAccess/api/payments.ts';
import calculateDiscounts from '../hooks/discounts';
import totals from '../hooks/getTotals';

const CartTotals = () => {
  const selectedOrder = useSelectedOrder();
  const orderPayment = useOrderPayment();
  const setOrderPayment = useSetOrderPayment();
  //eslint-disable-line
  const lineHeight = 2;
  const showGiftCard = () => {
    let giftCardAmount = 0;
    orderPayment?.forEach((ele) => {
      if (ele.payment.paymentMethodInfo.method === 'GIFT_CARD') {
        giftCardAmount = ele.payment.transactions.reduce((acc, transaction) => {
          if (transaction.type === 'Charge' && transaction.state === 'Success') {
            return acc + transaction.amount.centAmount;
          }
          return acc;
        }, 0);
      }
    });
    giftCardAmount = toDollar(giftCardAmount);
    return giftCardAmount;
  };
  // totals.getPayment(selectedOrder);

  const getPaymentInfo = async () => {
    // Retrieves the payment calls to get payment info.
    const { payments } = selectedOrder.paymentInfo;
    const paymentArray = payments.map(async (payment) => {
      return PaymentService.getPaymentById(payment.id);
    });
    // Retrieves the expanded payment data from ct, for each one of the payments.
    const paymentResponse = await Promise.all(paymentArray);
    setOrderPayment(paymentResponse);
    return paymentResponse;
  };
  useEffect(() => {
    getPaymentInfo(selectedOrder);
  }, [selectedOrder.id]);

  return (
    <List sx={{ mt: 2, backgroundColor: 'lightGrayBG', p: 2 }}>
      <ListItem sx={{ p: 0, justifyContent: 'space-between' }}>
        <Typography align="left" fontSize={14} sx={{ lineHeight }}>
          {copyText.Orders.OrderTotals.subtotal}
        </Typography>
        <Typography align="right">${totals.getPreTaxTotal(selectedOrder)}</Typography>
      </ListItem>
      <ListItem sx={{ p: 0, justifyContent: 'space-between' }}>
        <Typography align="left" fontSize={14} sx={{ lineHeight }}>
          {copyText.Orders.OrderTotals.shipping}
        </Typography>
        <Typography align="right">${totals.getShipping(selectedOrder)}</Typography>
      </ListItem>
      <ListItem sx={{ p: 0, justifyContent: 'space-between' }}>
        <Typography align="left" fontSize={14} sx={{ lineHeight }}>
          {copyText.Orders.OrderTotals.taxes}
        </Typography>
        <Typography align="right">${totals.getTax(selectedOrder)}</Typography>
      </ListItem>
      <ListItem sx={{ color: 'darkAqua', p: 0, justifyContent: 'space-between' }}>
        <Typography align="left" fontSize={14} sx={{ lineHeight }}>
          {copyText.Orders.OrderTotals.discounts}
        </Typography>
        <Typography align="right">-${calculateDiscounts(selectedOrder).totalDiscounts}</Typography>
      </ListItem>
      <ListItem sx={{ color: 'darkAqua', p: 0, justifyContent: 'space-between' }}>
        <Typography align="left" fontSize={14} sx={{ lineHeight }}>
          {copyText.Orders.OrderTotals.giftCards}
        </Typography>
        <Typography align="right">-${showGiftCard()}</Typography>
      </ListItem>
      <Divider />
      <ListItem disablePadding sx={{ mt: 3, justifyContent: 'space-between' }}>
        <Typography align="left" sx={{ fontWeight: 600, fontSize: 14 }}>
          {copyText.Orders.OrderTotals.orderTotal}
        </Typography>
        <Typography align="right" sx={{ fontWeight: 600 }}>
          ${totals.getTaxedTotal(selectedOrder)}
        </Typography>
      </ListItem>
    </List>
  );
};

export default CartTotals;
