import axios from 'axios';
import getAccessToken from 'utils/getAccessToken';
import { ShippingMethodParam } from './interfaces/cartEndpointInterfaces';

// Microservice agnostic base url for DMI endpoints
// const middlewareBaseURL = envVars.REACT_APP_CART_MS_URL;

/* SUNSET Update this 'mock' api call to actually retrieve backend data */

const getAllShippingMethods = () => {
  const availableShippingMethods = [
    'Carry Out',
    'FEDEX_GROUND',
    'In-Home Setup',
    'Pick Up',
    'Fedex 2 Day',
  ];
  return availableShippingMethods;
};

/**
 * getShippingMethods - Gets valid shipping methods for a cart
 * @param cartId the CT id of the cart
 * @returns An array of available shipping methods given a cart
 */
const getShippingMethods = async (cartId: string) => {
  const hasRequiredParams = !!cartId;
  const oktaToken = getAccessToken();
  if (hasRequiredParams) {
    // const oktaToken = Fetch_Promise.Get_Okta_Token();
    const uri = `${process.env.REACT_APP_MS_URL}/carts/${cartId}/shipping-methods`;

    const config = {
      headers: {
        role: 'agent',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oktaToken}`,
      },
    };
    const response = await axios.get(uri, config);

    // TODO: Map these return types to an interface
    return response.data;
  }

  throw new Error('Get shipping methods requires: cartId');
};

/**
 * setShippingMethods - adds valid shipping methods given items in the cart
 * @param params Contains an array of shipping methods associated to cart skus
 * @param cartId The CT id for a cart
 * @returns An updated cart or undefined
 */
const setShippingMethods = async (shippingMethods: ShippingMethodParam, cartId: string) => {
  const oktaToken = getAccessToken();
  const hasRequiredParams = !!cartId && !!shippingMethods;

  if (hasRequiredParams) {
    // const oktaToken = Fetch_Promise.Get_Okta_Token();
    const uri = `${process.env.REACT_APP_MS_URL}/carts/${cartId}/shipping-methods`;
    const reqBody = { shippingMethods };
    let config = {
      headers: {
        role: 'agent',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oktaToken}`,
      },
    };
    let response = await axios.post(uri, JSON.stringify(reqBody), config);
    return response.data;
  }

  throw new Error('Set shipping methods requires: cartId, shippingMethods');
};

export default {
  getShippingMethods,
  getAllShippingMethods,
  setShippingMethods,
};
