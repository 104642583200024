import { useState, createContext, useContext, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import ProductServices from 'dataAccess/pimApi/product.ts';
import CartService from 'dataAccess/api/cart.ts';
import getAccessToken from 'utils/getAccessToken';
import { getMostRecentCart } from 'utils/cartHistory';
import locationStorage from 'contexts/location';

const useStore = () => {
  const [oldCart, setOldCart] = useState({});
  const [cart, setCart] = useState();
  const [shopProducts, setShopProducts] = useState();
  const [shopCategories, setShopCategories] = useState();
  const [shopProductsView, setShopProductsView] = useState();
  const [shippingMethods, setShippingMethods] = useState([]);
  const [addToCartLoading, setAddToCartLoading] = useState(false);
  const [oktaClaims, setOktaClaims] = useState();
  const oktaAuth = useOktaAuth();
  const { locations, getLocations } = locationStorage([]);

  const getCategories = async () => {
    try {
      const result = await ProductServices.getCategories();
      setShopCategories(result);
      return result;
    } catch (err) {
      throw new Error(err);
    }
  };

  const getProducts = async () => {
    try {
      const categories = await getCategories();
      const result = await ProductServices.getAllProducts(categories);
      setShopProducts(result);
    } catch (err) {
      throw new Error(err);
    }
  };

  const populateProducts = () => {
    const accessToken = getAccessToken();
    if (accessToken) {
      getProducts();
    }
  };

  const populateOktaClaims = () => {
    const oktaData = JSON.parse(sessionStorage.getItem('okta-token-storage'));
    if (oktaData) {
      setOktaClaims(oktaData?.accessToken?.claims);
    }
    return null;
  };

  const populateCart = async () => {
    const currentCartId = getMostRecentCart();
    if (currentCartId) {
      const result = await CartService.getCart(currentCartId);
      setCart(result.data);
    }
  };

  useEffect(() => {
    populateProducts();
    populateCart();
    populateOktaClaims();
  }, [oktaAuth?.oktaAuth?.authStateManager?._authState]);

  useEffect(() => {
    getLocations();
  }, []);

  return {
    cart,
    setCart,
    oldCart,
    setOldCart,
    shopCategories,
    setShopCategories,
    shopProducts,
    setShopProducts,
    shopProductsView,
    setShopProductsView,
    shippingMethods,
    setShippingMethods,
    addToCartLoading,
    setAddToCartLoading,
    oktaClaims,
    locations,
  };
};

const StoreContext = createContext(null);

// eslint-disable-next-line react/prop-types
export const StoreContextProvider = ({ children }) => (
  <StoreContext.Provider value={useStore()}>{children}</StoreContext.Provider>
);

export const useCart = () => useContext(StoreContext).cart;
export const useOldCart = () => useContext(StoreContext).oldCart;
export const useSetOldCart = () => useContext(StoreContext).setOldCart;
export const useSetCart = () => useContext(StoreContext).setCart;
export const useShopCategories = () => useContext(StoreContext).shopCategories;
export const useSetShopCategories = () => useContext(StoreContext).setShopCategories;
export const useShopProducts = () => useContext(StoreContext).shopProducts;
export const useSetShopProducts = () => useContext(StoreContext).setShopProducts;
export const useShopProductsView = () => useContext(StoreContext).shopProductsView;
export const useSetShopProductsView = () => useContext(StoreContext).setShopProductsView;
export const useShippingMethods = () => useContext(StoreContext).shippingMethods;
export const useSetShippingMethods = () => useContext(StoreContext).setShippingMethods;
export const useAddToCartLoading = () => useContext(StoreContext).addToCartLoading;
export const useSetAddToCartLoading = () => useContext(StoreContext).setAddToCartLoading;
export const useOktaClaims = () => useContext(StoreContext).oktaClaims;
export const useLocations = () => useContext(StoreContext).locations;
