import { string } from 'prop-types';
import { Select, FormControl, InputLabel, MenuItem, CircularProgress, Grid } from '@mui/material';
import { useState, useEffect } from 'react';
import shippingService from 'dataAccess/api/cart.shipping.ts';
import { copyText } from 'language';
import storeUtils from 'utils/storeLocation';
import shippingUtils from 'utils/shippingContext';
import { useCart, useSetCart } from 'contexts';
import stores from 'dataAccess/api/stores.ts';

const ShippingMethod = ({ sku, lineItemId }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [availableShippingMethods, setAvailableShippingMethods] = useState([]);
  const [removeMattress, setRemoveMattress] = useState(false);
  const [pickupStoreKey, setPickupStoreKey] = useState('UT-01');
  const [loading, setLoading] = useState(true);
  const [brickStores, setBrickStores] = useState([]);
  const cart = useCart();
  const setCart = useSetCart();

  const getStores = async () => {
    setLoading(true);
    const result = await stores.getStores();
    setBrickStores(result);
    setLoading(false);
  };

  const getSelectedShippingMethod = (shippingMethods) => {
    const shippingMethod = shippingMethods.customLineItems?.filter((item) => {
      return item.custom?.fields?.lineItemsIds?.includes(lineItemId);
    });
    return shippingMethod[0]?.name['en-US'];
  };

  const updateCart = async (contextCopy) => {
    try {
      setLoading(true);
      const result = await shippingService.setShippingMethods(contextCopy, cart?.id);
      setCart(result);
      const shippingMethod = getSelectedShippingMethod(result);
      // shipping method assigned in cart
      setSelectedOption(shippingMethod);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleClose = async (store) => {
    const result = await shippingUtils.setSkuStore(sku, store.key, cart?.id);
    setPickupStoreKey(store.key);
    updateCart(result);
  };

  const updateMattressRemoval = (needsRemoval) => {
    const contextCopy = shippingUtils.getShippingMethodStorage();
    contextCopy.find((option) => option.skus.includes(sku)).needsRemoval = needsRemoval;
    shippingUtils.setShippingMethodStorage(contextCopy);
    updateCart(contextCopy);
  };

  const displayAdditionalOptions = () => {
    return selectedOption === 'Pick Up' || selectedOption === 'In-Home Setup' ? 8 : 16;
  };

  const updateShippingMethod = async (newShippingMethod) => {
    setLoading(true);
    const storeKey = storeUtils.getStoreKey();
    const contextShippingMethod = {
      key: newShippingMethod.key,
      name: newShippingMethod.name,
      price: newShippingMethod.price,
      offerRemovalOption: false,
      storeKey,
    };
    const contextCopy = shippingUtils.getShippingMethodStorage();
    const result = await shippingUtils.updateShippingContext(
      contextCopy,
      contextShippingMethod,
      sku,
    );
    await updateCart(result);
    setLoading(false);
  };

  const getSkuStore = () => {
    const contextCopy = shippingUtils.getShippingMethodStorage();
    const storeKey = shippingUtils.getSkuStore(contextCopy, sku);
    return storeKey;
  };
  const getCurrentShippingMethod = async () => {
    const result = cart?.customLineItems?.filter((item) => {
      return item.custom?.fields?.lineItemsIds?.includes(lineItemId);
    });
    if (result.length) {
      // shipping method assigned in cart
      setSelectedOption(result[0]?.name['en-US']);
      const mattressRemoval = result[0]?.custom.fields.mattress_removal;
      if (mattressRemoval) {
        setRemoveMattress(1);
      } else {
        setRemoveMattress(0);
      }
    }
    setTimeout(() => {
      setLoading(false);
    }, [3000]);
  };

  const getAvailableShippingMethods = async () => {
    if (cart?.id) {
      const result = await shippingService.getShippingMethods(cart?.id);
      const parsedMethods = await shippingUtils.parseShippingMethods(result, sku);
      setAvailableShippingMethods(parsedMethods[0].groups[0].shippingMethods);
      getCurrentShippingMethod();
      getStores();
      setPickupStoreKey(getSkuStore());
    }
  };

  useEffect(() => {
    getAvailableShippingMethods();
  }, [cart]);

  return (
    <Grid container display="flex" flexDirection="row" justifyContent="space-between" columns={16}>
      <Grid item xs={16} sm={displayAdditionalOptions()}>
        <FormControl data-testid="form-control-shippingMethod" fullWidth>
          <InputLabel id="select-shipping-method">
            {copyText.Cart.CartTools.shippingMethod}
          </InputLabel>
          <Select
            id="select-shipping-method"
            fullWidth
            label="Shipping Method"
            sx={{
              height: '40px',
            }}
            value={selectedOption}
            IconComponent={() => null}
            disabled={loading}
            endAdornment={loading ? <CircularProgress size={25} color="primary" /> : null}
          >
            {availableShippingMethods.map((option) => {
              return (
                <MenuItem
                  key={option.name}
                  value={option.name}
                  onClick={() => updateShippingMethod(option)}
                >
                  {option.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      {selectedOption === 'Pick Up' && (
        <Grid item xs={15} sm={7}>
          <FormControl fullWidth>
            <InputLabel id="pickup-from-shipping">{copyText.Cart.CartTools.pickupFrom}</InputLabel>
            <Select
              id="pickup-from-shipping"
              fullWidth
              label="Pickup From"
              sx={{ color: 'primary', height: '40px' }}
              value={pickupStoreKey}
              IconComponent={() => null}
              disabled={loading}
              endAdornment={loading ? <CircularProgress size={25} color="primary" /> : null}
            >
              {brickStores.map((store) => {
                return (
                  <MenuItem key={store.key} value={store.key} onClick={() => handleClose(store)}>
                    {store.name['en-US']}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      )}
      {selectedOption === 'In-Home Setup' && (
        <Grid item xs={15} sm={7}>
          <FormControl fullWidth>
            <InputLabel id="select-remove-mattress">
              {copyText.Cart.CartTools.removeMattress}
            </InputLabel>
            <Select
              id="select-remove-mattress"
              fullWidth
              label="Remove Mattress"
              sx={{ color: 'primary', height: '40px' }}
              value={removeMattress}
              IconComponent={() => null}
              disabled={loading}
              endAdornment={loading ? <CircularProgress size={25} color="primary" /> : null}
            >
              <MenuItem value={1} onClick={() => updateMattressRemoval(true)}>
                {copyText.App.yes}
              </MenuItem>
              <MenuItem value={0} onClick={() => updateMattressRemoval(false)}>
                {copyText.App.no}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};

ShippingMethod.propTypes = {
  sku: string.isRequired,
  lineItemId: string.isRequired,
};

export default ShippingMethod;
