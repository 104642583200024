import React from 'react';
import { DialogTitle, CircularProgress, DialogContent } from '@mui/material';
import { copyText } from 'language';

const LoadingModal = () => {
  return (
    <>
      <DialogTitle id="alert-dialog-title">{copyText.Orders.RefundModal.refundPreview}</DialogTitle>
      <DialogContent>
        <CircularProgress color="secondary" size={120} sx={{ ml: 25 }} />
      </DialogContent>
    </>
  );
};

export default LoadingModal;
