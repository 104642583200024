const second = 1000;
const minute = second * 60;
const hour = minute * 60;

const time = {
  seconds: (len) => {
    return second * len;
  },
  minutes: (len) => {
    return minute * len;
  },
  hours: (len) => {
    return hour * len;
  },
};
export default time;
