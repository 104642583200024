import React from 'react';
import { Box, Grid } from '@mui/material';
// import { copyText } from 'language';
import { useOktaClaims } from 'contexts';
import RefundModal from './Refunds/RefundModal';
import ReturnModal from './ReturnModal';

const ReturnBox = () => {
  const oktaClaims = useOktaClaims();
  return (
    <Box>
      <Grid container mb={40} align="center">
        <Grid item xs={16} sm={4}>
          <ReturnModal />
        </Grid>
        <Grid item xs={16} sm={4}>
          {oktaClaims?.mpos_manager && <RefundModal />}
        </Grid>
        {/* <Grid item xs={16} sm={4}>
          <Button variant="outlined">{copyText.Orders.ReturnBox.exchange}</Button>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default ReturnBox;
