import React, { useState, useEffect } from 'react';
import { List, ListItem, Typography, Tooltip, IconButton, Snackbar } from '@mui/material';
import plural from 'utils/PluralProducts';
import { useSetCart } from 'contexts';
import convertCentToDollar from 'utils/convertCentToDollar';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import discountService from 'dataAccess/api/cart.discounts.ts';
import { InfoOutlined } from '@mui/icons-material';
import { copyText, lang } from 'language';
import { bool, shape } from 'prop-types';
import GiftCard from './components/GiftCard';

const CartTotals = ({ cart, editGiftCards, editCoupons }) => {
  const lineHeight = 2;
  const setCart = useSetCart();
  const [totalPrice, setTotalPrice] = useState('$0.00');
  const [promoCodeTotals, setPromoCodeTotals] = useState('$0.00');
  const [discountCodes, setDiscountCodes] = useState([]);
  const discountTotals = '$0.00';
  const [taxTotals, setTaxTotals] = useState('$0.00');
  const [shippingTotals, setShippingTotals] = useState('$0.00');
  const [recyclingFeeTotal, setRecyclingFeeTotal] = useState(0);
  const [giftCardTotals, setGiftCardTotals] = useState('$0.00');
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const getDiscountCodes = () => {
    const discountCodesArray = [];
    cart?.lineItems?.forEach((lineItem) => {
      const discounts = JSON.parse(lineItem.custom?.fields?.discounts_json);
      // only keep discount code from discounts
      discounts.forEach((item) => {
        if (discountCodesArray.length === 0) {
          discountCodesArray.push(item);
        }
        let repeat = 0;
        discountCodesArray.forEach((ele) => {
          if (ele.display_name === item.display_name) {
            repeat++;
          }
        });
        if (repeat === 0) {
          discountCodesArray.push(item);
        }
      });
      return null;
    });
    setDiscountCodes(discountCodesArray);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowError(false);
    setErrorMessage('');
  };

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
      <CloseIcon />
    </IconButton>
  );

  const removeDiscountCode = async (code) => {
    try {
      const result = await discountService.removeDiscountFromCart(cart.id, code.coupon_code);
      setCart(result.data);
    } catch {
      setShowError(true);
      setErrorMessage(copyText.Cart.PromoCode.removeError);
    }
  };

  const getCartPromoCodeDiscounts = () => {
    let totalPromoCodeDiscounts = 0;
    if (cart?.lineItems?.length > 0) {
      cart?.lineItems?.forEach((lineItem) => {
        const parsedPromoCodeDiscounts = JSON.parse(lineItem.custom?.fields?.discounts_json)[0];
        totalPromoCodeDiscounts += parsedPromoCodeDiscounts?.cent_amount * lineItem.quantity || 0;
      });
      setPromoCodeTotals(convertCentToDollar(totalPromoCodeDiscounts));
      return totalPromoCodeDiscounts;
    }
    setPromoCodeTotals('$0.00');
    return 0;
  };

  const getTax = () => {
    if (cart?.taxedPrice) {
      const totalGross = cart?.taxedPrice?.totalGross?.centAmount;
      const totalNet = cart?.taxedPrice?.totalNet?.centAmount;
      setTaxTotals(convertCentToDollar(totalGross - totalNet));
      return totalGross - totalNet;
    }
    if (cart?.lineItems?.length === 0 || cart?.lineItems) {
      setTaxTotals('$0.00');
      return 0;
    }
    setTaxTotals(copyText.Cart.CartTools.addShippingAddress);
    return 0;
  };
  const getShippingTotals = () => {
    let total = 0;
    cart?.customLineItems?.forEach((lineItem) => {
      if (lineItem.name[lang] !== 'Recycling Fee') {
        total += lineItem.totalPrice?.centAmount || 0;
      }
    });
    setShippingTotals(convertCentToDollar(total));
    return total;
  };

  const getRecyclingFee = () => {
    let recyclingFee = 0;
    cart?.customLineItems?.forEach((lineItem) => {
      if (lineItem.name[lang] === 'Recycling Fee') {
        recyclingFee += lineItem.taxedPrice?.totalGross?.centAmount || 0;
      }
    });
    setRecyclingFeeTotal(convertCentToDollar(recyclingFee));
    return recyclingFee;
  };
  const getGiftCardTotals = () => {
    let total = 0;
    cart?.giftCards?.forEach((card) => {
      total += card.amount?.centAmount || 0;
    });
    const cartTotal = cart?.taxedPrice?.totalGross?.centAmount;
    if (total > cartTotal) {
      setGiftCardTotals(convertCentToDollar(cartTotal));
      return cartTotal;
    }
    setGiftCardTotals(convertCentToDollar(total));
    return total;
  };

  const calculateTotalPrice = () => {
    const price = cart?.totalPrice?.centAmount;
    const promos = getCartPromoCodeDiscounts();
    const shipping = getShippingTotals();
    const recycling = getRecyclingFee();
    const total = price + promos - shipping - recycling;

    return total;
  };

  const displayRemoveButton = (discount) => {
    if (discount.method === 'coupon') {
      return true;
    }
    return false;
  };

  useEffect(() => {
    getRecyclingFee();
    getGiftCardTotals();
    getCartPromoCodeDiscounts();
    getTax();
    getShippingTotals();
    setTotalPrice(convertCentToDollar(calculateTotalPrice()));
    getDiscountCodes();
  }, [cart]);

  return (
    <>
      <List sx={{ mb: 2 }}>
        <ListItem sx={{ p: 0, justifyContent: 'space-between' }}>
          <Typography align="left" fontSize={14} sx={{ lineHeight }}>
            {`Price (${cart?.totalLineItemQuantity || 0} item${
              plural(cart?.totalLineItemQuantity || 0) ? 's' : ''
            })`}
          </Typography>
          <Typography align="right">{totalPrice}</Typography>
        </ListItem>
        <ListItem sx={{ p: 0, justifyContent: 'space-between' }}>
          <Typography align="left" fontSize={14} sx={{ lineHeight }}>
            {copyText.Cart.CartTools.shipping}
          </Typography>
          <Typography align="right">{shippingTotals}</Typography>
        </ListItem>
        <ListItem sx={{ p: 0, justifyContent: 'space-between' }}>
          <Typography align="left" fontSize={14} sx={{ lineHeight }}>
            {copyText.Cart.CartTools.tax}
          </Typography>
          <Typography align="right">{taxTotals}</Typography>
        </ListItem>
        {recyclingFeeTotal !== '$0.00' && (
          <ListItem sx={{ p: 0, justifyContent: 'space-between' }}>
            <Box display="flex" alignItems="center">
              <Typography fontSize={14} sx={{ lineHeight }}>
                {copyText.Cart.CartTools.recyclingFee}
              </Typography>
              <Tooltip
                sx={{ ml: 1 }}
                disableFocusListener
                title={copyText.Cart.CartTools.recyclingFeeExplanation}
              >
                <InfoOutlined fontSize="small" />
              </Tooltip>
            </Box>
            <Typography align="right">{recyclingFeeTotal}</Typography>
          </ListItem>
        )}
        <ListItem sx={{ color: 'darkAqua', p: 0, justifyContent: 'space-between' }}>
          <Typography align="left" fontSize={14} sx={{ lineHeight }}>
            {copyText.Cart.CartTools.discounts}
          </Typography>
          <Typography align="right">-{discountTotals}</Typography>
        </ListItem>
        <ListItem sx={{ color: 'darkAqua', p: 0, justifyContent: 'space-between' }}>
          <Typography align="left" fontSize={14} sx={{ lineHeight }}>
            {copyText.Cart.CartTools.giftCards}
            {editGiftCards && <GiftCard />}
          </Typography>
          <Typography align="right">-{giftCardTotals}</Typography>
        </ListItem>
        <ListItem sx={{ color: 'darkAqua', p: 0, justifyContent: 'space-between' }}>
          <Typography align="left" fontSize={14} sx={{ lineHeight }}>
            {copyText.Cart.CartTools.promos}
          </Typography>
          <Typography align="right">-{promoCodeTotals}</Typography>
        </ListItem>
        {discountCodes.length > 0 && (
          <ListItem sx={{ p: 0, justifyContent: 'space-between' }}>
            <Typography align="left" fontSize={14} sx={{ lineHeight }}>
              {copyText.Cart.CartTools.appliedDiscounts}:
            </Typography>
          </ListItem>
        )}
        {discountCodes.map((discountCode) => {
          return (
            <ListItem key={discountCode.id} sx={{ p: 0, justifyContent: 'space-between' }}>
              <Typography align="left" fontSize={14} sx={{ lineHeight }}>
                {discountCode.display_name}
              </Typography>
              {/* SUNSET we need to bring this back once we have discount codes again. */}
              {editCoupons && displayRemoveButton(discountCode) && (
                <IconButton onClick={() => removeDiscountCode(discountCode)}>
                  <CloseIcon />
                </IconButton>
              )}
            </ListItem>
          );
        })}
      </List>
      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={errorMessage}
        action={action}
      />
    </>
  );
};

CartTotals.propTypes = {
  cart: shape({
    lineItems: shape({}).isRequired,
    taxedPrice: shape({}).isRequired,
  }).isRequired,
  editGiftCards: bool,
  editCoupons: bool,
};

CartTotals.defaultProps = {
  editGiftCards: true,
  editCoupons: true,
};
export default CartTotals;
