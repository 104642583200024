import React, { useState, useEffect } from 'react';
import {
  useOrderInput,
  useFoundOrderData,
  useCurrentPage,
  useSelectedStore,
  useSetSelectedStore,
  useSetOrderInput,
  useSetSelectedOrder,
  useSetOrderPayment,
} from 'contexts/orders';
import SearchLayout from 'components/layouts/SearchLayout';
import storeLocation from 'utils/storeLocation';
import { copyText, lang } from 'language';
import OrderFilterModal from './OrderFilterModal';
import UseOrderSearchFunctions from './hooks/OrderSearchFunctions';

const Orders = () => {
  const [orderTableData, setOrderTableData] = useState([]);
  const [filterModal, setFilterModal] = useState(false);
  const currentPage = useCurrentPage(); // This is the current page that is used for the orders micro service.
  const foundOrderData = useFoundOrderData();
  const orderInput = useOrderInput();
  const setOrderInput = useSetOrderInput();
  const selectedStore = useSelectedStore();
  const setSelectedStore = useSetSelectedStore();
  const setSelectedOrder = useSetSelectedOrder();
  const setOrderPayment = useSetOrderPayment();

  const {
    useTodaysStoreOrders,
    useSearchUserInput,
    useHandlePageChange,
    useFormatOrderTableData,
    loading,
  } = UseOrderSearchFunctions();

  const handleInput = (event) => {
    setOrderInput(event.target.value);
  };

  const orderColumns = {
    idColumn: copyText.Orders.OrderList.orderNumber,
    columns: [
      copyText.Orders.OrderList.purchaseDate,
      copyText.Orders.OrderList.customerName,
      copyText.Orders.OrderList.customerEmail,
      copyText.Orders.OrderList.location,
    ],
  };

  const openFilterModal = () => {
    setOrderInput('');
    setFilterModal(true);
  };
  const closeFilterModal = () => {
    setFilterModal(false);
  };

  const handleListItemClick = (row) => {
    setOrderPayment(null);
    setSelectedOrder(row.data);
  };

  useEffect(() => {
    setOrderTableData(useFormatOrderTableData(foundOrderData.results));
  }, [foundOrderData.results]);

  useEffect(() => {
    setSelectedStore(storeLocation.getLocationStorage().name[lang]);
    useTodaysStoreOrders();
  }, []);

  return (
    <SearchLayout
      searchLabel={copyText.Orders.Orders.orderSearch}
      searchFunction={useSearchUserInput}
      searchInput={orderInput}
      handleInput={handleInput}
      loading={loading}
      columns={orderColumns.columns}
      idColumn={orderColumns.idColumn}
      rowData={orderTableData}
      numRows={foundOrderData.total}
      handleListItemClick={handleListItemClick}
      handlePageChange={useHandlePageChange}
      currentPage={currentPage - 1} // This is the page value that is used for the table pagination;
      setFilterToOpen={openFilterModal}
      FilterComponent={OrderFilterModal({
        modalOpenStatus: filterModal,
        selectedStore,
        setSelectedStore,
        closeFilterModal,
        searchFunction: useSearchUserInput,
      })}
    />
  );
};

export default Orders;
