import { shape, string, arrayOf, number, bool } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DeleteOutline, ExpandMore, ExpandLess, EditOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardMedia,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import CartService from 'dataAccess/api/cart.lineItem.ts';
import { copyText, lang } from 'language';
import EditCartProduct from 'components/EditCartProduct/EditCartModal';
import CartProductService from 'dataAccess/api/cart.product.ts';
import shippingContextUtils from 'utils/shippingContext';
import { useCart, useSetCart } from 'contexts';
import QuantitySelect from './components/QuantitySelect';
import ShippingMethod from './components/ShippingMethod';

const CartCard = ({ productData, readOnly, showEdit, showShippingMethod }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [variants, setVariants] = useState([]);
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [basePrice, setBasePrice] = useState(null);
  const [productDescription, setProductDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const cart = useCart();
  const setCart = useSetCart();

  const getVariants = async () => {
    if (showEdit) {
      const result = await CartProductService.getProductVariants(productData.productId);
      if (result.variants) {
        setVariants(result.variants);
      }
    }
  };

  useEffect(() => {
    getVariants();
  }, []);

  const updateQuantity = async (quantity) => {
    try {
      setLoading(true);
      const params = {
        lineItemId: productData.id,
        cartId: cart?.id,
        quantity,
      };
      const updatedCart = await CartService.updateItemQuantityInCart(params);
      setCart(updatedCart);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getDescription = (variant) => {
    const description = variant.attributes.find((attribute) => attribute.name === 'title');
    if (description) {
      setProductDescription(description.value);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const removeFromCart = async () => {
    try {
      setLoading(true);
      const contextCopy = shippingContextUtils.getShippingMethodStorage();
      const updatedShippingContext = shippingContextUtils.removeLineItemShipping(
        productData.variant.sku,
        contextCopy,
      );
      shippingContextUtils.setShippingMethodStorage(updatedShippingContext);
      const updatedCart = await CartService.removeItemFromCart(cart?.id, productData.id);
      setCart(updatedCart);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getPrice = () => {
    const finalPrice = productData.price.value.centAmount;
    const initialPrice = productData.price.value.centAmount;
    setBasePrice(finalPrice / 100);
    const discounts = JSON.parse(productData.custom?.fields?.discounts_json);
    if (discounts.length > 0) {
      const result = discounts.reduce((acc, discount) => {
        return acc + discount.cent_amount;
      }, initialPrice);
      setDiscountedPrice(result / 100);
    }
  };

  useEffect(() => {
    getVariants();
    getPrice();
    getDescription(productData.variant);
  }, []);

  return (
    <Card sx={{ mt: 1, mb: 5 }} elevation={0} data-testid="cartCard-wrapper">
      <Grid container columns={16} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Grid item xs={16} sm={5} md={5} display="flex" alignContent="center">
          <CardMedia
            component="img"
            sx={{
              pr: 2,
              objectPosition: 'center' /* Center the image within the element */,
              height: '100%',
              width: '100%',
            }}
            src={productData.variant?.images[0]?.url}
            alt=""
          />
        </Grid>
        <Grid item xs={16} sm={11} md={10} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography component="p" sx={{ fontWeight: 500 }} color="primary">
              {productData.name[lang]}
            </Typography>
            {!readOnly && (
              <IconButton
                data-testid="cart-card-remove-item"
                onClick={removeFromCart}
                aria-label={copyText.Cart.CartTools.removeFromCart}
                disabled={loading}
              >
                {loading ? <CircularProgress size={25} /> : <DeleteOutline />}
              </IconButton>
            )}
          </Box>
          <Grid marginRight={2} alignItems="center" container justifyContent="space-between">
            <Grid item display="flex" alignItems="center">
              <Typography variant="subtitle2">{productDescription}</Typography>
              {variants.length > 1 && showEdit && (
                <IconButton size="small" onClick={openModal} sx={{ ml: 1 }}>
                  <EditOutlined fontSize="16px" />
                </IconButton>
              )}
              <EditCartProduct
                handleClose={closeModal}
                product={productData}
                showModal={isOpen}
                variants={variants}
              />
            </Grid>
            <Grid container sx={{ mb: 1 }}>
              {discountedPrice && (
                <Typography
                  variant="subtitle2"
                  fontWeight="semi-bold"
                  sx={{ textDecoration: 'line-through' }}
                  align="right"
                  marginRight={1}
                >
                  ${discountedPrice}
                </Typography>
              )}
              <Typography
                variant="subtitle2"
                fontWeight="semi-bold"
                align="right"
                marginRight={1}
                color={discountedPrice ? 'darkAqua' : 'black'}
              >
                ${basePrice}
              </Typography>
            </Grid>
          </Grid>
          <Grid marginTop={1} container justifyContent="space-between" alignItems="center">
            {!readOnly ? (
              <Grid item xs={16} sm={4} md={3}>
                <QuantitySelect numItem={productData.quantity} updateQuantity={updateQuantity} />
              </Grid>
            ) : (
              <Grid xs={16} item display="flex" alignItems="center" justifyContent="space-between">
                <Typography>
                  {copyText.App.quantityAbbreviated}: {productData.quantity}
                </Typography>
                {/* SUNSET: enable this button when we decide what data to show */}
                <Button
                  disabled
                  onClick={() => setExpanded(!expanded)}
                  variant="text"
                  endIcon={expanded ? <ExpandLess /> : <ExpandMore />}
                >
                  {copyText.App.details}
                </Button>
              </Grid>
            )}
            {!readOnly && showShippingMethod && (
              <Grid item xs={16} sm={8} md={8}>
                <ShippingMethod sku={productData.variant.sku} lineItemId={productData.id} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

CartCard.propTypes = {
  productData: shape({
    productId: string.isRequired,
    quantity: number.isRequired,
    variant: shape({
      attributes: arrayOf(
        shape({
          name: string.isRequired,
          // TODO: Account for different types for values
          // value: string.isRequired,
        }),
      ).isRequired,
      // availability: shape({
      //   channels: shape(objectOf(string)).isRequired,
      // }),
      images: arrayOf(
        shape({
          dimensions: shape({
            w: number.isRequired,
            h: number.isRequired,
          }).isRequired,
          // label: string.isRequired,
          url: string.isRequired,
        }),
      ).isRequired,
      id: number.isRequired,
    }),
    price: shape({
      value: shape({
        centAmount: number.isRequired,
      }).isRequired,
    }).isRequired,
    name: shape({
      'en-US': string.isRequired,
    }).isRequired,
  }).isRequired,
  readOnly: bool,
  showEdit: bool,
  showShippingMethod: bool,
};

CartCard.defaultProps = {
  readOnly: false,
  showEdit: false,
  showShippingMethod: false,
};

export default CartCard;
