import React, { useState, useEffect } from 'react';
import { func, string, number, arrayOf, objectOf, shape } from 'prop-types';
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormControl,
} from '@mui/material';
import { copyText } from 'language';

const InitialForm = ({ refundInfo, updateInfo, closeModal, refundPreview }) => {
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (refundInfo.error) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }, [refundInfo.error]);
  return (
    <>
      <DialogTitle id="alert-dialog-title">{copyText.Orders.RefundModal.refund}</DialogTitle>
      <DialogContent>
        <Typography>Agent: {refundInfo.agentName}</Typography>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel htmlFor="refund-amount">
            {copyText.Orders.RefundModal.refundAmount}
          </InputLabel>
          <OutlinedInput
            id="refund-amount"
            value={refundInfo.refundAmount}
            error={refundInfo.error}
            required
            autoFocus
            onChange={updateInfo}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            label={copyText.Orders.RefundModal.refundAmount}
            name="refundAmount"
          />
        </FormControl>
        {showError ? (
          <Typography color="error">{copyText.Orders.RefundModal.amountError}</Typography>
        ) : null}
        <TextField
          label={copyText.Orders.RefundModal.refundReason}
          placeholder={copyText.Orders.RefundModal.refundReason}
          onChange={updateInfo}
          fullWidth
          multiline
          name="refundReason"
          rows={3}
          sx={{ mt: 1 }}
        />
      </DialogContent>
      <DialogActions sx={{ mb: 2, mr: 2 }}>
        <Button variant="outlined" onClick={closeModal}>
          <Typography variant="h3" component="span">
            {copyText.App.cancel}
          </Typography>
        </Button>
        <Button variant="contained" onClick={refundPreview}>
          <Typography variant="h3" component="span" color="white">
            {copyText.Orders.RefundModal.refund}
          </Typography>
        </Button>
      </DialogActions>
    </>
  );
};

InitialForm.propTypes = {
  refundInfo: shape({
    agentName: string,
    refundAmount: string,
    refundReason: string,
    orderId: string,
    manualRefund: arrayOf(objectOf({ paymentId: string, refund: number })),
  }).isRequired,
  closeModal: func.isRequired,
  updateInfo: func.isRequired,
  refundPreview: func.isRequired,
};

export default InitialForm;
