import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Divider,
  List,
  ListItem,
} from '@mui/material';
import copyText from 'language/enUS';
import { lang } from 'language';
import { useEffect, useState } from 'react';
import storeLocation from 'utils/storeLocation';
import convertCentToDollar from 'utils/convertCentToDollar';
import { useOktaAuth } from '@okta/okta-react';
import logo from 'media/images/purple-logo.svg';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import getDateTime from 'utils/getDateTime';
import PickListItem from './components/PickListItem';

const OrderModal = ({ open, order, closeModal }) => {
  const [address, setAddress] = useState('');
  const [storeName, setStoreName] = useState('');
  const [shippingTotals, setShippingTotals] = useState('');
  const [taxTotals, setTaxTotals] = useState('');
  const [totalPrice, setTotalPrice] = useState('');
  const [promoCodeDiscounts, setPromoCodeDiscounts] = useState([]);
  const [recyclingFeeTotal, setRecyclingFeeTotal] = useState(null);
  const [agentName, setAgentName] = useState('');
  const { oktaAuth } = useOktaAuth();
  const getAgent = () => {
    const name = oktaAuth.authStateManager?._authState?.idToken?.claims?.name;
    if (name) {
      setAgentName(name);
    }
  };
  const receiptStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    m: 0,
    mt: 2,
    p: 0,
  };

  const getShippingTotals = () => {
    let total = 0;
    order?.customLineItems?.forEach((lineItem) => {
      if (lineItem.name[lang] !== 'Recycling Fee') {
        total += lineItem.totalPrice?.centAmount || 0;
      }
    });
    setShippingTotals(convertCentToDollar(total));
    return total;
  };

  const getRecyclingFee = () => {
    let recyclingFee = 0;
    order?.customLineItems?.forEach((lineItem) => {
      if (lineItem.name[lang] === 'Recycling Fee') {
        recyclingFee += lineItem.taxedPrice?.totalGross?.centAmount || 0;
      }
    });
    setRecyclingFeeTotal(convertCentToDollar(recyclingFee));
    return recyclingFee;
  };

  const getGiftCardAmount = (giftCard) => {
    return convertCentToDollar(giftCard.amount.centAmount);
  };

  const getGiftCardNumber = (giftCard) => {
    return giftCard.giftCardNumber.slice(giftCard.giftCardNumber.length - 4);
  };

  const getLineItemPrice = (lineItem) => {
    const lineItemPrice = lineItem.totalPrice.centAmount;
    let discounts = [];
    if (lineItem.custom?.fields?.discounts_json) {
      discounts = JSON.parse(lineItem.custom.fields.discounts_json);
    }
    const sumWithInitial = discounts.reduce(
      (accumulator, discount) => accumulator + discount.cent_amount,
      lineItemPrice,
    );
    return convertCentToDollar(sumWithInitial);
  };

  const getTax = () => {
    const totalGross = order?.taxedPrice?.totalGross?.centAmount;
    const totalNet = order?.taxedPrice?.totalNet?.centAmount;
    setTaxTotals(convertCentToDollar(totalGross - totalNet || 0));
  };

  const getShippingMethod = (lineItem) => {
    const result = order.customLineItems?.filter((customLineItem) => {
      return customLineItem?.custom?.fields?.lineItemsIds?.includes(lineItem.id);
    });
    return result[0].custom.fields.netsuite_shipping_name;
  };

  const getGiftCardTotals = () => {
    const total = 0;
    let giftCards = 0;
    if (order?.giftCards) {
      giftCards = order?.giftCards?.reduce((acc, next) => {
        return acc + next.amount.centAmount;
      }, total);
    }

    return giftCards;
  };

  const getPromoCodeDiscounts = () => {
    const totalPromoCodeDiscounts = [];
    order?.lineItems?.forEach((lineItem) => {
      const parsedPromoCodeDiscounts = JSON.parse(lineItem.custom?.fields?.discounts_json);
      parsedPromoCodeDiscounts.reduce(
        (acc = [], discount) =>
          acc.push({
            id: discount.id,
            centAmount: convertCentToDollar(discount.cent_amount),
            couponCode: discount.coupon_code,
            method: discount.method,
            displayName: discount.display_name,
          }),
        totalPromoCodeDiscounts,
      );
    });
    setPromoCodeDiscounts(totalPromoCodeDiscounts);
  };

  const calculateTotalPrice = () => {
    const giftCards = getGiftCardTotals();
    const price = order?.taxedPrice?.totalGross?.centAmount;
    setTotalPrice(convertCentToDollar(price - giftCards));
  };

  useEffect(() => {
    setAddress(storeLocation.getStoreAddress());
    setStoreName(storeLocation.getLocationStorage().name[lang]);
    getPromoCodeDiscounts();
    getShippingTotals();
    getTax();
    calculateTotalPrice();
    getAgent();
    getRecyclingFee();
  }, []);
  return (
    <Dialog scroll="body" open={open} fullWidth>
      <DialogTitle sx={{ displayPrint: 'none', backgroundColor: 'lightGrayBG' }}>
        {copyText.Cart.PaymentResponse.orderCreated}!
      </DialogTitle>
      <DialogTitle sx={{ display: 'none', displayPrint: 'block' }}>
        <img src={logo} alt={copyText.Header.logo.alt} style={{ height: '3rem' }} />
      </DialogTitle>
      <DialogContent
        sx={{
          textAlign: 'center',
          alignContent: 'center',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h6">{storeName}</Typography>
        <Typography>{address.streetName}</Typography>
        <Typography>{address.streetNumber}</Typography>
        <Typography>
          {`${address.city},
                ${address.state}`}
        </Typography>
        <Typography marginTop={4} variant="h6">
          {copyText.App.orderNumber} {order?.orderNumber}
        </Typography>
        <List>
          <ListItem sx={receiptStyles}>
            <Typography variant="p" component="strong" align="left">
              {copyText.App.item}
            </Typography>
            <Typography variant="p" component="strong" align="right">
              {copyText.App.price}
            </Typography>
          </ListItem>
          <Divider />
          {order?.lineItems.map((lineItem) => {
            return (
              <ListItem key={lineItem.id} sx={receiptStyles}>
                <Box>
                  <Typography variant="p" component="strong" align="left">
                    {lineItem.name[lang]}
                  </Typography>
                  <br />
                  {getShippingMethod(lineItem) === 'Pick Up' ? (
                    <PickListItem lineItem={lineItem} order={order} />
                  ) : (
                    <>
                      <Typography align="left" display="flex">
                        {lineItem.name[lang]} x {lineItem.quantity}
                      </Typography>
                      <Typography display="flex" align="left">
                        {getShippingMethod(lineItem)}
                      </Typography>
                    </>
                  )}
                  <Typography variant="p">SKU: {lineItem.variant?.sku}</Typography>
                </Box>
                <Typography align="right">{getLineItemPrice(lineItem)}</Typography>
              </ListItem>
            );
          })}
          <Divider sx={{ mt: 2 }} />
          {order?.giftCards?.map((giftCard) => {
            return (
              <ListItem sx={receiptStyles} key={giftCard.giftCardNumber}>
                <Typography variant="p" align="left">
                  {copyText.Cart.receipt.giftCardXX}
                  {getGiftCardNumber(giftCard)}
                </Typography>
                <Typography align="right">-{getGiftCardAmount(giftCard)}</Typography>
              </ListItem>
            );
          })}
          {promoCodeDiscounts.map((discountCode) => {
            return (
              <ListItem key={discountCode.id} sx={receiptStyles}>
                <Box>
                  <Typography variant="p" component="strong">
                    {discountCode.method}
                  </Typography>
                  <Typography>{discountCode.displayName}</Typography>
                  <Typography>{discountCode.couponCode}</Typography>
                </Box>
                <Typography>-{discountCode.centAmount}</Typography>
              </ListItem>
            );
          })}
          <ListItem sx={receiptStyles}>
            <Typography align="left">{copyText.Cart.CartTools.shipping}</Typography>
            <Typography align="right">{shippingTotals}</Typography>
          </ListItem>
          {recyclingFeeTotal && (
            <ListItem sx={{ p: 0, justifyContent: 'space-between' }}>
              <Typography>{copyText.Cart.CartTools.recyclingFee}</Typography>
              <Typography align="right">{recyclingFeeTotal}</Typography>
            </ListItem>
          )}
          <ListItem sx={receiptStyles}>
            <Typography align="left">{copyText.Cart.CartTools.tax}</Typography>
            <Typography align="right">{taxTotals}</Typography>
          </ListItem>
          <Divider sx={{ mt: 2 }} />
          <ListItem sx={receiptStyles}>
            <Typography variant="p" component="strong" align="left">
              {copyText.Cart.receipt.totalCaps}
            </Typography>
            <Typography variant="p" component="strong" align="right">
              {totalPrice}
            </Typography>
          </ListItem>
          <Divider sx={{ mt: 2 }} />
          <ListItem sx={receiptStyles}>
            <Typography variant="p" component="strong" align="right">
              {copyText.Cart.receipt.soldTo}
            </Typography>
          </ListItem>
          <ListItem
            sx={{
              display: 'flex',
              flexDirection: 'column',
              margin: 0,
              marginTop: 2,
              padding: 0,
            }}
          >
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
              <Typography variant="p" component="strong" fontSize={20}>
                {`${order.shippingAddress.firstName} ${order.shippingAddress.lastName}`}
              </Typography>
              <Typography fontSize={14}>{order.shippingAddress.streetName}</Typography>
              <Typography variant="p" fontSize={14}>
                {`${order.shippingAddress.city}, ${order.shippingAddress.state} ${order.shippingAddress.postalCode}`}
              </Typography>
            </Box>
            <Box
              marginTop={4}
              marginBottom={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Typography variant="p" component="strong">
                {copyText.App.date}: {getDateTime()}
              </Typography>
              <Typography variant="p" component="strong">
                {copyText.Cart.receipt.soldBy}: {agentName}
              </Typography>
            </Box>
          </ListItem>
          <Divider />
          <ListItem
            sx={{
              display: 'flex',
              flexDirection: 'column',
              margin: 0,
              marginTop: 2,
              padding: 0,
            }}
          >
            <Box
              marginTop={2}
              marginBottom={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Typography>{copyText.App.returnWarrantyPolicy}</Typography>
              <Typography>{copyText.App.pleaseVisitUrl}</Typography>
              <Typography>{copyText.App.returnsURL}</Typography>
            </Box>
            <Divider />

            <Typography variant="p" component="strong">
              {copyText.App.customerSupportCenter}
            </Typography>
            <Typography variant="p" component="strong">
              {copyText.App.customerSupportPhone}
            </Typography>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions sx={{ p: 2, backgroundColor: 'lightGrayBG' }}>
        <Button
          variant="outlined"
          sx={{ display: 'block', displayPrint: 'none', mr: 2 }}
          onClick={closeModal}
        >
          {copyText.App.close}
        </Button>
        <Button
          variant="contained"
          sx={{ display: 'block', displayPrint: 'none' }}
          onClick={() => window.print()}
        >
          {copyText.App.print}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

OrderModal.propTypes = {
  open: bool.isRequired,
  order: shape({
    customLineItems: arrayOf(shape({})).isRequired,
    taxedPrice: shape({
      totalGross: shape({
        centAmount: number.isRequired,
      }).isRequired,
      totalNet: shape({
        centAmount: number.isRequired,
      }).isRequired,
    }).isRequired,
    lineItems: arrayOf(shape({})).isRequired,
    orderNumber: string.isRequired,
    createdAt: string.isRequired,
  }).isRequired,
  closeModal: func.isRequired,
};

export default OrderModal;
