import axios from 'axios';
import getAccessToken from 'utils/getAccessToken';
import cloverUtils from 'utils/clover';
import { v4 as uuidv4 } from 'uuid';
import storeLocation from 'utils/storeLocation';
import getDateTime from 'utils/getDateTime';
import convertCentToDollar from 'utils/convertCentToDollar';
import logo from 'media/images/purple-logo.svg';
import copyText from 'language/enUS';
// TODO: unit tests for functions in this file
// import { Buffer } from 'buffer';
// import purpleLogo from 'media/images/purple-logo-64';
const cloverAuthorization = () => {
  const cloverMid = cloverUtils.getCloverMid();
  const cloverUrl = process.env.REACT_APP_CLOVER_URL;
  const clientId = process.env.REACT_APP_CLOVER_SANDBOX_APP_ID;

  if (process.env.REACT_APP_CLOVER_ENABLE_AUTH === 'true') {
    window.location.href = `${cloverUrl}/oauth/authorize?client_id=${clientId}&merchant_id=${cloverMid}`;
  }
};

const getToken = async (authCode: string) => {
  const oktaToken = getAccessToken();
  const ERR_CODE = 'ERR_BAD_REQUEST';
  const provider = 'CLOVER';
  const newUri = `${process.env.REACT_APP_MS_URL}/payment-hub/integrations/authorize-session`;
  try {
    const config = {
      headers: {
        role: 'agent',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oktaToken}`,
      },
    };
    const reqBody = {
      provider,
      authCode,
    };
    const result = await axios.post(newUri, JSON.stringify(reqBody), config);
    return result;
  } catch (error) {
    return null;
  }
};

const displayMessage = async (deviceSerial: string, name: string) => {
  const tokenStorage = cloverUtils.getCloverAccessToken();
  const token = tokenStorage.token;
  const url = `${process.env.REACT_APP_CLOVER_API}/connect/v1/device/display`;
  const pos = process.env.REACT_APP_CLOVER_POS;
  let config = {
    headers: {
      authorization: `Bearer ${token}`,
      'x-clover-device-id': deviceSerial,
      'x-pos-id': pos,
      'content-type': 'application/json',
    },
  };
  const body = {
    beep: true,
    text: `${name} ${copyText.Terminal.connected}`,
  };
  const result = await axios.post(url, body, config);
};

const getDevices = async () => {
  const mid = cloverUtils.getCloverMid();
  const tokenStorage = cloverUtils.getCloverAccessToken();
  if (tokenStorage) {
    const token = tokenStorage?.token;

    const uri = `${process.env.REACT_APP_CLOVER_API}/v3/merchants/${mid}/devices`;
    let config = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const devices = await axios.get(uri, config);
    return devices;
  }
  throw new Error('No clover token found');
};

const displayThankYou = async () => {
  const tokenStorage = cloverUtils.getCloverAccessToken();
  const deviceSerial = cloverUtils.getSessionCloverDevice();
  const token = tokenStorage.token;
  const url = `${process.env.REACT_APP_CLOVER_API}/connect/v1/device/thank-you`;
  const pos = process.env.REACT_APP_CLOVER_POS;
  let config = {
    headers: {
      authorization: `Bearer ${token}`,
      'x-clover-device-id': deviceSerial,
      'x-pos-id': pos,
      'content-type': 'application/json',
    },
  };
  const body = {};
  await axios.post(url, body, config);
};

const displayWelcome = async () => {
  const tokenStorage = cloverUtils.getCloverAccessToken();
  const deviceSerial = cloverUtils.getSessionCloverDevice();
  const token = tokenStorage.token;
  const url = `${process.env.REACT_APP_CLOVER_API}/connect/v1/device/welcome`;
  const pos = process.env.REACT_APP_CLOVER_POS;
  let config = {
    headers: {
      authorization: `Bearer ${token}`,
      'x-clover-device-id': deviceSerial,
      'x-pos-id': pos,
      'content-type': 'application/json',
    },
  };
  const body = {};
  await axios.post(url, body, config);
};

const paymentRequest = async (cartId: string, paymentTotal: number) => {
  try {
    const tokenStorage = cloverUtils.getCloverAccessToken();
    const deviceSerial = cloverUtils.getSessionCloverDevice();
    const token = tokenStorage.token;
    const uri = `${process.env.REACT_APP_CLOVER_API}/connect/v1/payments`;
    const idempotencyKey = uuidv4();

    //removes all dashes from cartId
    const externalPaymentId = cartId.replace(/-/g, '');

    const pos = process.env.REACT_APP_CLOVER_POS;
    let config = {
      headers: {
        authorization: `Bearer ${token}`,
        'x-clover-device-id': deviceSerial,
        'x-pos-id': pos,
        'idempotency-key': idempotencyKey,
        'content-type': 'application/json',
      },
    };
    const body = {
      amount: paymentTotal, // cent amount
      // final: false,
      // capture: false,
      externalPaymentId,
    };
    const result = await axios.post(uri, body, config);
    return result;
  } catch (error) {
    return error.response;
  }
};

const cancelCurrentOperation = async () => {
  const tokenStorage = cloverUtils.getCloverAccessToken();
  const deviceSerial = cloverUtils.getSessionCloverDevice();
  const token = tokenStorage.token;
  const pos = process.env.REACT_APP_CLOVER_POS;
  const uri = `${process.env.REACT_APP_CLOVER_API}/connect/v1/device/cancel`;
  let config = {
    headers: {
      authorization: `Bearer ${token}`,
      'x-clover-device-id': deviceSerial,
      'x-pos-id': pos,
      'content-type': 'application/json',
    },
  };
  const body = {};
  const result = await axios.post(uri, body, config);
  return result;
};

// TODO (Todd F): this doesn't work because of the formatting of the image.
// TODO: figure out how to Clover wants the image formatted
// const printReceiptImage = async () => {
//   const buff = Buffer.from(logo);
//   const tokenStorage = cloverUtils.getCloverAccessToken();
//   const deviceSerial = cloverUtils.getSessionCloverDevice();
//   const token = tokenStorage.token;
//   const uri = `${process.env.REACT_APP_CLOVER_URL}/connect/v1/device/print/image`;
//   const pos = process.env.REACT_APP_CLOVER_POS;
//   let config = {
//     headers: {
//       authorization: `Bearer ${token}`,
//       'x-clover-device-id': deviceSerial,
//       'x-pos-id': pos,
//       'content-type': 'application/json',
//     },
//   };
//   const body = {
//     image: purpleLogo,
//   };

//   const result = await axios.post(uri, body, config);
//   return result;
// };

const printReceipt = async (paymentResult: any, order: any) => {
  // await printReceiptImage();
  const storeAddress = storeLocation.getStoreAddress();
  const cardType = paymentResult?.data?.payment?.cardTransaction?.cardType;
  const last4 = paymentResult?.data?.payment?.cardTransaction?.last4;
  const amount = convertCentToDollar(paymentResult?.data?.payment?.amount);
  const tokenStorage = cloverUtils.getCloverAccessToken();
  const deviceSerial = cloverUtils.getSessionCloverDevice();
  const token = tokenStorage.token;
  const pos = process.env.REACT_APP_CLOVER_POS;
  const uri = `${process.env.REACT_APP_CLOVER_API}/connect/v1/device/print/text`;
  let config = {
    headers: {
      authorization: `Bearer ${token}`,
      'x-clover-device-id': deviceSerial,
      'x-pos-id': pos,
      'content-type': 'application/json',
    },
  };

  const body = {
    text: [
      `Purple Innovation`,
      `Store: ${storeLocation.getStoreName()}`,
      `${storeAddress.streetName}`,
      `${storeAddress.streetNumber}`,
      `${storeAddress.city}, ${storeAddress.state} ${storeAddress.postalCode}`,
      ``,
      `Order #: ${order?.data?.orderNumber}`,
      ``,
      `Total: ${amount}`,
      `${cardType} ${last4}`,
      ``,
      `Date: ${getDateTime()}`,
      ``,
    ],
  };
  const result = await axios.post(uri, body, config);
  return result;
};

const checkDeviceStatus = async () => {
  const tokenStorage = cloverUtils.getCloverAccessToken();
  const deviceSerial = cloverUtils.getSessionCloverDevice();
  const token = tokenStorage.token;
  const pos = process.env.REACT_APP_CLOVER_POS;
  const uri = `${process.env.REACT_APP_CLOVER_API}/connect/v1/device/ping`;
  let config = {
    headers: {
      authorization: `Bearer ${token}`,
      'x-clover-device-id': deviceSerial,
      'x-pos-id': pos,
      'content-type': 'application/json',
    },
  };
  const body = {};
  const result = await axios.get(uri, config);
  return result;
};

export default {
  cloverAuthorization,
  displayMessage,
  getDevices,
  getToken,
  paymentRequest,
  displayThankYou,
  displayWelcome,
  cancelCurrentOperation,
  checkDeviceStatus,
  printReceipt,
};
