import stores from 'dataAccess/api/stores.ts';
import { lang } from 'language';
import clover from './clover';

const getLocationStorage = () => {
  const storeLocationData = JSON.parse(sessionStorage.getItem('store-data'));
  if (storeLocationData) {
    return storeLocationData;
  }
  return null;
};
// TODO: unit tests for clearLocationStorage, getLocationStorage, and setLocationStorage
const getLocation = async () => {
  return new Promise((res, rej) => {
    navigator.geolocation.getCurrentPosition((position) => {
      res(position.coords);
      if (position.coords) {
        res(position.coords);
      } else {
        rej(position);
      }
    });
  });
};

const getStoreAddress = () => {
  const locationStorage = getLocationStorage();
  const address = locationStorage.supplyChannels[0]?.obj?.address || '';
  return address;
};

const clearLocationStorage = () => {
  sessionStorage.removeItem('store-data');
};

const getStoreKey = () => {
  const storeLocationData = JSON.parse(sessionStorage.getItem('store-data'));
  if (storeLocationData) {
    return storeLocationData.key;
  }
  return null;
};

const setLocationStorage = (store) => {
  sessionStorage.setItem('store-data', JSON.stringify(store));
  clover.clearCloverStorage();
};

const getStoreByKey = async (storeKey) => {
  const result = await stores.getStores();
  const foundStore = result.filter((store) => store.key === storeKey);
  return foundStore[0] || null;
};

const getStoreName = () => {
  const locationStorage = getLocationStorage();
  const address = locationStorage.name[lang] || 'Purple Innovation';
  return address;
};

export default {
  getLocation,
  getLocationStorage,
  setLocationStorage,
  clearLocationStorage,
  getStoreAddress,
  getStoreKey,
  getStoreByKey,
  getStoreName,
};
