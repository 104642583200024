import clover from 'dataAccess/api/clover.ts';
import storeUtils from 'utils/storeLocation';
// TODO: write unit tests for all functions in this file
const getCloverAccessToken = () => {
  const cloverToken = JSON.parse(sessionStorage.getItem('clover-token-storage'));
  if (cloverToken) {
    return cloverToken;
  }
  return null;
};

const setCloverAccessToken = (tokenStorage) => {
  window.sessionStorage.setItem('clover-token-storage', JSON.stringify(tokenStorage));
};

const setSessionCloverDevice = (deviceSerial) => {
  window.sessionStorage.setItem('clover-device', deviceSerial);
  window.dispatchEvent(new Event('storage'));
};

const getSessionCloverDevice = () => {
  return window.sessionStorage.getItem('clover-device');
};

const clearCloverStorage = () => {
  sessionStorage.removeItem('clover-token-storage');
  window.sessionStorage.removeItem('clover-device');
};

/**
 * Checks if clover token exists and matches store OR if user manually selected a location
 * If false, then checks if a code for getting an access token is in the URL
 * OR checks if the user manually selected a new location.
 * If either is true, redirects to clover for authentication.
 * else, uses the code in the URL to get a new token
 * stores the new token in session storage
 * @param {*} store the selected store for comparing against the store associated with the access token
 * @param {*} manualSelect if a user manually selects a store, redirect should always occur.
 * This helps to avoid end-user store selection constraints.
 */
const updateCloverAccessToken = async () => {
  const storeLocation = await storeUtils.getLocationStorage();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get('code');
  // get a token if you have a code
  if (code) {
    const result = await clover.getToken(code);
    if (result) {
      const tokenStorage = {
        key: storeLocation.key,
        token: result.data.access_token,
      };
      setCloverAccessToken(tokenStorage);
    }
  }
};

const getCloverMid = () => {
  const storeLocationData = storeUtils.getLocationStorage();
  return storeLocationData?.custom?.fields?.cloverMerchantId || null;
};

const getDevices = async () => {
  const result = await clover.getDevices();
  return result.data?.elements;
};

const displayMessage = async (serial, name) => {
  await clover.displayMessage(serial, name);
};

export default {
  setCloverAccessToken,
  getCloverAccessToken,
  updateCloverAccessToken,
  clearCloverStorage,
  getDevices,
  getCloverMid,
  displayMessage,
  setSessionCloverDevice,
  getSessionCloverDevice,
};
