import { useState, useEffect } from 'react';
import { Badge, Button, CircularProgress, Snackbar, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { shape, string } from 'prop-types';
import { addToCart } from 'utils/cart';
import { copyText } from 'language';
import { useCart, useSetCart, useSetAddToCartLoading, useAddToCartLoading } from 'contexts';
import shippingContext from 'utils/shippingContext';
import CloseIcon from '@mui/icons-material/Close';

const AddToCartBtn = ({ details }) => {
  const loading = useAddToCartLoading();
  const [status, setStatus] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const setLoading = useSetAddToCartLoading();
  const [singleButtonLoading, setSingleButtonLoading] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const cart = useCart();
  const setCart = useSetCart();

  const quantityInCart = () => {
    if (cart?.lineItems) {
      const prod = cart?.lineItems.find((lineItem) => lineItem.variant.sku === details.sku);
      if (prod) {
        setQuantity(prod.quantity);
      }
    }
  };

  const getError = (err) => {
    return err.response?.data?.errors[0]?.code || copyText.App.errorOccurred;
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
      <CloseIcon />
    </IconButton>
  );

  const handleNewShipping = async (updatedCart) => {
    try {
      const result = await shippingContext.handleNewLineItem(updatedCart, details.sku);
      if (result === null) {
        setCart(updatedCart);
      } else {
        setCart(result);
      }
    } catch (error) {
      setLoading(false);
      setSingleButtonLoading(false);
      setStatus(`${copyText.Cart.CartTools.failedShippingMethods}: ${getError(error)}`);
      setOpenSnackbar(true);
    }
  };

  const handleAddToCart = async () => {
    try {
      setLoading(true);
      setSingleButtonLoading(true);
      const updatedCart = await addToCart(details, cart);
      await handleNewShipping(updatedCart);
      setLoading(false);
      setSingleButtonLoading(false);
    } catch (error) {
      setLoading(false);
      setSingleButtonLoading(false);
      setStatus(`${copyText.Cart.CartTools.failedAddToCart}: ${getError(error)}`);
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    quantityInCart();
  }, [cart]);

  return (
    <>
      <Badge
        data-testid="add-to-cart-badge"
        color="secondary"
        overlap="rectangular"
        badgeContent={quantity}
        component="p"
      >
        <Button
          disabled={loading}
          data-testid="add-to-cart-button"
          variant="contained"
          startIcon={singleButtonLoading ? null : <AddIcon />}
          onClick={handleAddToCart}
          sx={{ height: '2.5rem' }}
        >
          {singleButtonLoading ? <CircularProgress size={20} /> : copyText.ProductCard.addToCart}
        </Button>
      </Badge>
      <Snackbar
        sx={{ color: 'primary' }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={status}
        action={action}
      />
    </>
  );
};

AddToCartBtn.propTypes = {
  details: shape({
    sku: string.isRequired,
  }).isRequired,
};

export default AddToCartBtn;
