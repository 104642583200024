import React from 'react';
import { Typography, DialogContent, DialogActions, Button } from '@mui/material';
import { copyText } from 'language';

const ResultModal = ({ refundInfo, closeModal, changeView, views }) => {
  const tryAgain = () => {
    changeView(views.form);
  };

  const checkResult = () => {
    const { result } = refundInfo;
    if (result?.payments_not_refunded?.length || Object.keys(result).length === 0) {
      return (
        <>
          <DialogContent>
            <Typography variant="h4">{copyText.Orders.RefundModal.refundError}</Typography>
          </DialogContent>
          <DialogActions sx={{ mb: 2, mr: 2 }}>
            <Button variant="outlined" onClick={closeModal}>
              <Typography variant="h3" component="span">
                {copyText.App.cancel}
              </Typography>
            </Button>
            <Button variant="contained" onClick={tryAgain}>
              <Typography variant="h3" component="span" color="white">
                {copyText.Orders.RefundModal.tryAgain}
              </Typography>
            </Button>
          </DialogActions>
        </>
      );
    }
    return (
      <>
        <DialogContent>
          <Typography variant="h4">{copyText.Orders.RefundModal.refundSuccess}</Typography>
        </DialogContent>
        <DialogActions sx={{ mb: 2, mr: 2 }}>
          <Button variant="outlined" onClick={closeModal}>
            <Typography variant="h3" component="span">
              {copyText.App.close}
            </Typography>
          </Button>
        </DialogActions>
      </>
    );
  };
  return checkResult();
};

export default ResultModal;
