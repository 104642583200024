import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CampaignIcon from '@mui/icons-material/Campaign';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { string } from 'prop-types';
import { copyText } from 'language';

const Footer = ({ pathname }) => {
  const history = useHistory();
  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
      }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={pathname}
        onChange={(event, newValue) => {
          history.push(newValue);
          // window.location.reload();
        }}
        sx={{
          backgroundColor: 'lightGrayBG',
          padding: 5,
        }}
      >
        <BottomNavigationAction
          label={copyText.Footer.shop}
          value="/shop"
          icon={<ShoppingCartIcon fontSize="large" />}
        />
        <BottomNavigationAction
          label={copyText.Footer.promotions}
          value="/promotion"
          icon={<CampaignIcon fontSize="large" />}
        />
        <BottomNavigationAction
          label={copyText.Footer.orders}
          value="/orders"
          icon={<InventoryOutlinedIcon fontSize="large" />}
        />
      </BottomNavigation>
    </Paper>
  );
};

Footer.propTypes = {
  pathname: string.isRequired,
};

export default Footer;
