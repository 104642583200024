import React, { useState, useEffect } from 'react';
import { func, string, number, arrayOf, objectOf, shape } from 'prop-types';
import {
  Card,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  DialogContent,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { copyText } from 'language';
import RefundService from 'dataAccess/api/refunds/refunds.ts';
import convertCentToDollar from 'utils/convertCentToDollar';

const RefundPreview = ({
  closeModal,
  payments,
  refundInfo,
  selectedOrder,
  updateResult,
  changeView,
  views,
}) => {
  const [hasGiftCard, setGiftCard] = useState(false);
  const [manualPayment, setManualPayment] = useState({
    payment_id: '',
    refund: 0,
  });

  const processAutoRefund = async () => {
    const input = {
      orderId: selectedOrder.id,
      refundAmount: refundInfo.refundAmount,
      reason: refundInfo.refundReason,
    };
    changeView(views.loading);
    const result = await RefundService.automaticRefund(input);
    updateResult(result.result);
  };
  const processManualRefund = async () => {
    const input = {
      orderId: selectedOrder.id,
      manualRefunds: [manualPayment],
      reason: refundInfo.refundReason,
    };
    changeView(views.loading);
    const result = await RefundService.manualRefund(input);
    updateResult(result.result);
  };

  const filterCharges = (payment) => {
    return payment.transactions.filter((ele) => {
      return ele.type === 'Charge' && ele.state === 'Success';
    });
  };
  const getPaymentAmount = (payment) => {
    const charges = filterCharges(payment);
    const totalAmount = charges.reduce((acc, ele) => {
      return acc + ele.amount.centAmount;
    }, 0);
    return convertCentToDollar(totalAmount);
  };
  const getPaymentCreation = (payment) => {
    const charges = filterCharges(payment);
    const createdDate = new Date(charges[0].timestamp);
    return createdDate.toLocaleDateString();
  };

  const checkPayments = (payment) => {
    if (payment.paymentMethodInfo.method === 'GIFT_CARD') {
      // setGiftCard(true);
      // setManualPayment({ payment_id: payment.id, refund: (+refundInfo.refundAmount).toFixed(2) });
      return (
        <ListItemText primaryTypographyProps={{ fontWeight: 'bold', color: 'primary' }}>
          {`${copyText.Orders.RefundModal.giftCardRefund}`}
        </ListItemText>
      );
    }
    const refundAmount = manualPayment.refund
      ? manualPayment.refund
      : (+refundInfo.refundAmount).toFixed(2);
    return (
      <ListItemText>
        {`${copyText.Orders.RefundModal.appliedRefund}: $${refundAmount}`}
      </ListItemText>
    );
  };

  const checkRefundType = () => {
    const onClickHandler = hasGiftCard ? processManualRefund : processAutoRefund;
    return (
      <Button variant="contained" onClick={onClickHandler}>
        <Typography variant="h3" component="span" color="white">
          {copyText.Orders.RefundModal.confirm}
        </Typography>
      </Button>
    );
  };

  useEffect(() => {
    payments.forEach((ele) => {
      if (ele.paymentMethodInfo.method === 'GIFT_CARD') {
        setGiftCard(true);
      } else {
        const refundableAmount = ele.refund_properties.unrefunded_amount;
        // eslint-disable-next-line prettier/prettier
        const refundAmount = refundInfo.refundAmount <= refundableAmount ? refundInfo.refundAmount : refundableAmount;
        setManualPayment({
          payment_id: ele.id,
          refund: Number.parseFloat(refundAmount),
        });
      }
    });
  }, []);

  return (
    <>
      <DialogTitle id="alert-dialog-title">{copyText.Orders.RefundModal.refundPreview}</DialogTitle>
      <DialogContent>
        <List>
          <ListItemText>{`${copyText.Orders.RefundModal.agent}: ${refundInfo.agentName}`}</ListItemText>
          {payments?.map((payment) => {
            return (
              <Card variant="outlined" sx={{ mb: 1 }} key={payment.id}>
                <ListItem>
                  <List>
                    <ListItemText primaryTypographyProps={{ variant: 'h6' }}>
                      {`${
                        copyText.Orders.RefundModal.payment
                      }: ${payment.paymentMethodInfo.method.replace('_', ' ')}`}
                    </ListItemText>
                    <ListItemText>
                      {`${copyText.Orders.RefundModal.paymentAmount}: ${getPaymentAmount(payment)}`}
                    </ListItemText>
                    <ListItemText>
                      {`${copyText.Orders.RefundModal.paymentDate}: ${getPaymentCreation(payment)}`}
                    </ListItemText>
                    {checkPayments(payment)}
                  </List>
                </ListItem>
              </Card>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={closeModal}>
          <Typography variant="h3" component="span">
            {copyText.App.cancel}
          </Typography>
        </Button>
        {checkRefundType()}
      </DialogActions>
    </>
  );
};

RefundPreview.propTypes = {
  closeModal: func.isRequired,
  updateResult: func.isRequired,
  changeView: func.isRequired,
  refundInfo: shape({
    agentName: string,
    refundAmount: string,
    refundReason: string,
    orderId: string,
    manualRefund: arrayOf(objectOf({ paymentId: string, refund: number })),
  }).isRequired,
  selectedOrder: shape({ id: string.isRequired }).isRequired,
  payments: arrayOf(shape({ id: string })).isRequired,
  views: objectOf(string).isRequired,
};
export default RefundPreview;
