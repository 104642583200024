import { v4 as uuidv4 } from 'uuid';
import CartLineitemService from 'dataAccess/api/cart.lineItem.ts';
import CartService from 'dataAccess/api/cart.ts';
import { addCartToHistory } from 'utils/cartHistory';
import CartDiscountService from 'dataAccess/api/cart.discounts.ts';

/**
 * Create an anonymous cart
 * @returns {Object} - initial cart object
 * @example
 *    createCart();
 *    returns: { id: string }
 */
const createCart = async () => {
  const anonymousId = uuidv4();
  const result = await CartService.createAnonymousCart(anonymousId);
  return result;
};

/**
 * Add items to a cart
 * @property {Object} details - product details
 * @property {Object} cart - the cart object from Context
 *
 * @example
 *    addToCart(
 *      details: { sku: string },
 *      cart: { cart: object, setCart: func}
 *    );
 */
const addToCart = async (details, cart) => {
  let initialCart = cart;
  if (!cart) {
    initialCart = await createCart();
    addCartToHistory(initialCart);
  }

  const params = {
    sku: details.sku,
    cartId: initialCart.id,
    quantity: 1,
  };

  const updatedCart = await CartLineitemService.addItemToCart(params);
  return updatedCart;
};

const addPromoCodeToCart = async (cart, promoCode) => {
  let resp;
  try {
    const updatedCart = await CartDiscountService.addDiscountToCart(cart.id, promoCode);
    resp = updatedCart;
  } catch (error) {
    throw new Error(error);
  }
  return resp;
};

export { addToCart, createCart, addPromoCodeToCart };
