import React from 'react';
import { Route, useHistory, Switch } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { string, shape } from 'prop-types';
import { useIdleTimer } from 'react-idle-timer';

// --- Global ---
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import oktaConfig from 'utils/oktaConfig';
import time from 'utils/time';
import theme from 'theme';
import { StoreContextProvider } from 'contexts';
import cloverUtils from 'utils/clover';

// --- Global ---

// --- Views ---
import ErrorPage from 'views/ErrorPage/ErrorPage';
import CartHistory from 'views/CartHistory/CartHistory';
import Shop from './views/Shop';
import Login from './views/Login/Login';
import Orders from './views/Orders';
import Cart from './views/Cart/Cart';

// --- Okta ---
const oktaAuth = new OktaAuth(oktaConfig);

const disallowedPaths = ['/', '/pin'];
const showPageTheme = (pathname) => {
  let showPage = true;
  const pathFound = !!disallowedPaths.find((path) => path === pathname);
  showPage = !pathFound;

  return showPage;
};

// --- Header ---
const HeaderContainer = ({ location }) => {
  const { pathname } = location;
  return showPageTheme(pathname) && <Header />;
};

HeaderContainer.propTypes = {
  location: shape({
    pathname: string.isRequired,
  }).isRequired,
};

// --- Footer ---
const FooterContainer = ({ location }) => {
  const { pathname } = location;
  return showPageTheme(pathname) && <Footer pathname={pathname} />;
};

FooterContainer.propTypes = {
  location: shape({
    pathname: string.isRequired,
  }).isRequired,
};

// --- App ---
const App = () => {
  const timeout = time.hours(5);
  const onIdle = () => {
    alert('timed out');
    cloverUtils.clearCloverStorage();
    oktaAuth.signOut();
  };

  useIdleTimer({ onIdle, timeout });

  const history = useHistory();
  const restoreOriginalUri = async () => {
    history.replace(toRelativeUrl('/shop', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <StoreContextProvider>
        <ThemeProvider theme={theme}>
          {/* <ShippingContext.Provider value={shippingValue}> */}
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <HeaderContainer location={window.location} />

          <article role="main">
            <Switch>
              {/* --- START Routes --- */}
              <Route exact path="/" component={Login} />
              <SecureRoute path="/shop" component={Shop} />
              <SecureRoute path="/orders" component={Orders} />
              <SecureRoute path="/cart" component={Cart} />
              <SecureRoute path="/cart-history" component={CartHistory} />
              <Route exact path="/login-okta" component={LoginCallback} />
              <SecureRoute path="*" component={ErrorPage} />
              {/* --- END Routes --- */}
            </Switch>
          </article>
          <FooterContainer location={window.location} />
        </ThemeProvider>
      </StoreContextProvider>
    </Security>
  );
};

export default App;
