import axios from 'axios';
import getAccessToken from 'utils/getAccessToken';

/**
 * getPriceBySku - Gets the price of an item based on the sku
 * @param sku the CT product sku
 * @returns the price for a product given the sku
 * @link https://onpurple.github.io/carts-ms/#/paths/~1{sku}~1catalog-promo-price/get
 */
const getPriceBySku = async (sku: string, oktaToken?: string) => {
  const hasRequiredParams = !!sku;

  const hasOktaToken = !!oktaToken;
  if (!hasOktaToken) {
    const tokenStorage = localStorage.getItem('okta-token-storage');
    oktaToken = tokenStorage && JSON.parse(tokenStorage).accessToken.accessToken;
  }

  if (hasRequiredParams) {
    // const oktaToken = Fetch_Promise.Get_Okta_Token();
    const uri = `${process.env.REACT_APP_MS_URL}/carts/${sku}/catalog-promo-price`;

    const config = {
      headers: {
        role: 'agent',
        'Content-Type': 'application/json',
        Authorization: `Bearer null`,
      },
    };
    const response = await axios.get(uri, config);

    // TODO: Map response interfaces
    // let response = await Fetch_Promise.Get(uri, oktaToken);
    return response;
  }

  throw new Error('Get price by sku requires: sku');
};

/**
 * getProductVariants - Gets the varients for a given product
 * @param productId the CT product id
 * @returns an array of variants
 * @link https://onpurple.github.io/carts-ms/#/paths/~1product~1{productId}~1variants/get
 */
const getProductVariants = async (productId: string) => {
  const hasRequiredParams = !!productId;
  const oktaToken = getAccessToken();
  if (hasRequiredParams) {
    // const oktaToken = Fetch_Promise.Get_Okta_Token();
    const uri = `${process.env.REACT_APP_MS_URL}/carts/product/${productId}/variants`;

    const config = {
      headers: {
        role: 'agent',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oktaToken}`,
      },
    };
    const response = await axios.get(uri, config);

    // TODO: Map response interfaces
    // let response = await Fetch_Promise.Get(uri, oktaToken);
    return response.data;
  }

  throw new Error('Get product variants requires: productId');
};

export default {
  getPriceBySku,
  getProductVariants,
};
