import { useState } from 'react';
import { Menu, Box, IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import CartHistorySearch from './components/CartHistorySearch';
import SendDraft from './components/SendDraft';

const CartMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setIsOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'end' }}>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ mr: 3 }}
        aria-controls={isOpen ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
      >
        <SettingsIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="user-menu"
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <SendDraft />
        <CartHistorySearch />
      </Menu>
    </Box>
  );
};

export default CartMenu;
