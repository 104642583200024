import axios from 'axios';
import getAccessToken from '../../utils/getAccessToken';
// const getStores = async () => {
//   const storeResult = await axios.get(`${process.env.REACT_APP_PIM_BASE_URL}/stores`);
//   return storeResult.data;
// };

const getStores = async (brickStores: boolean = true) => {
  const accessToken = getAccessToken();
  const uri = `${process.env.REACT_APP_MS_URL}/stores/?where=custom(fields(available = true))`;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    params: {},
  };
  let result = await axios.get(uri, config);
  if (brickStores) {
    // filter out virtual store locations
    result = result.data.results.filter((store) => store?.supplyChannels?.length > 0);
  }
  return result;
};

export default { getStores };
