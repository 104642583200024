import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Container, Box, Grid } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import logo from 'media/images/BETA_purple_logo.png';
import { copyText } from 'language';
import CartDrawer from 'components/CartDrawer/CartDrawer';
import theme from 'theme';
import clover from 'utils/clover';
import Greeting from './components/Greeting/Greeting';
import UserMenu from './components/UserMenu/UserMenu';

import Location from '../Location/Location';
import Feedback from './components/Feedback/Feedback';

const Header = () => {
  const [username, setUsername] = useState('User');
  const { oktaAuth } = useOktaAuth();
  const userData = () => {
    const name = oktaAuth.authStateManager?._authState?.idToken?.claims?.name;
    if (name) {
      const firstName = name.split(' ')[0];
      setUsername(firstName); // SUNSET: I don't know if this is the solution we want but this stops the header from changing sizes.
    }
  };

  useEffect(() => {
    userData();
  }, [oktaAuth.authStateManager._authState]);

  useEffect(() => {
    clover.updateCloverAccessToken();
  }, []);

  return (
    <Container>
      <AppBar
        position="fixed"
        color="secondary"
        sx={{
          boxShadow: 'none',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item>
              <Typography
                variant="h6"
                component="p"
                color="inherit"
                sx={{
                  flexGrow: 1,
                  textDecoration: 'none',
                }}
              >
                <img src={logo} alt={copyText.Header.logo.alt} style={{ height: '3rem' }} />
              </Typography>
            </Grid>
            <Grid
              item
              alignContent="center"
              alignItems="center"
              display="flex"
              justifyContent="center"
            >
              <Feedback username={username} />
            </Grid>
          </Grid>
          <Location />
          <Box sx={{ display: 'flex', padding: theme.spacing(1.5) }}>
            <Greeting userName={username} />
          </Box>
          <Box sx={{ display: 'flex', padding: theme.spacing(1.5) }}>
            <UserMenu />
          </Box>
          <CartDrawer />
        </Toolbar>
      </AppBar>
    </Container>
  );
};

export default Header;
