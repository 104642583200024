import { string, func, arrayOf } from 'prop-types';
import React, { useState } from 'react';
import { Typography, FormControl, InputLabel, Select, MenuItem, Grid } from '@mui/material';

const FilterBar = ({ title, filters = [{ arrKey: 1 }], returnFilters }) => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [currentFilter, setCurrentFilter] = useState('');
  const handleOnClick = (newFilter, filterBy) => {
    setCurrentFilter(filterBy);
    let filterList = selectedFilters.filter(
      (selectedFilter) => selectedFilter.filter !== newFilter,
    );
    filterList = [...filterList, { filter: newFilter, filterBy }];
    setSelectedFilters(filterList);
    returnFilters(filterList);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        backgroundColor: 'lightGrayBG',
        pl: 2,
        mb: 2,
        ml: -3,
        width: '105%',
      }}
    >
      <Grid item xs="auto">
        <Typography variant="h6" component="h2" color="primary">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        {filters.map((filter) => {
          return (
            <FormControl key={filter.arrKey} sx={{ width: 200, margin: 2 }}>
              <InputLabel id={`shop-filter-${filter.arrKey}`}>{filter.title}</InputLabel>
              <Select label={filter.title} value={currentFilter}>
                <MenuItem onClick={() => handleOnClick(filter.title, 'all')} value="all">
                  All
                </MenuItem>
                {filter.values?.map((value) => {
                  return (
                    <MenuItem
                      key={value.arrKey}
                      onClick={() => handleOnClick(filter.title, value.title)}
                      value={value.title}
                    >
                      {value.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          );
        })}
      </Grid>
    </Grid>
  );
};

FilterBar.propTypes = {
  title: string,
  filters: arrayOf(Object).isRequired,
  returnFilters: func.isRequired,
};

FilterBar.defaultProps = {
  title: 'All Products',
};

export default FilterBar;
