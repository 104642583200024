import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Grid,
  Divider,
  List,
  Typography,
  Container,
  CircularProgress,
  Select,
  MenuItem,
  Pagination,
} from '@mui/material';
import Stores from 'dataAccess/api/stores.ts';
import StoreUtility from 'utils/storeLocation';
import SearchIcon from '@mui/icons-material/Search';
import OrdersService from 'dataAccess/api/orders.ts';
import {
  useOrderInput,
  useFoundOrderData,
  useCurrentPage,
  useSetCurrentPage,
  useSelectedStore,
  useSetSelectedStore,
  useSetOrderInput,
  useSelectedOrder,
  useSetFoundOrderData,
} from 'contexts/orders';
import { copyText, lang } from 'language';
import OrderList from './components/OrderList';
import OrderDetails from './components/OrderDetails';
import OrderTotals from './components/OrderTotals';
import CustomerDetails from './components/CustomerDetails';
import OrderItems from './components/OrderItems';
import ReturnBox from './components/ReturnBox';
import RefundInfo from './components/RefundInfo';

const Orders = () => {
  const [searchType, setSearchType] = useState('');
  const [loading, setLoading] = useState(false);
  const [storeList, setStoreList] = useState([]);
  const [numPages, setNumPages] = useState(0);

  const currentPage = useCurrentPage(); // This is the current page that is used for the orders micro service.
  const setCurrentPage = useSetCurrentPage();
  const foundOrders = useFoundOrderData();
  const setFoundOrders = useSetFoundOrderData();
  const orderInput = useOrderInput();
  const setOrderInput = useSetOrderInput();
  const selectedStore = useSelectedStore();
  const setSelectedStore = useSetSelectedStore();
  const selectedOrder = useSelectedOrder();

  const searchTypes = {
    email: 'email',
    orderNumber: 'orderNumber',
    allStoreOrders: 'allStoreOrders',
    todaysStoreOrders: 'todaysStoreOrders',
  };

  const getTodaysStoreOrders = async (pageInput = 1) => {
    setCurrentPage(pageInput);
    setSearchType(searchTypes.todaysStoreOrders);
    const result = await OrdersService.getTodaysStoreOrders(pageInput);
    if (result?.orders.results) {
      setFoundOrders(result.orders.results);
      setNumPages(Math.ceil(result.orders.total / result.orders.limit));
    } else {
      setFoundOrders([]);
      setNumPages(0);
    }
    setLoading(false);
  };

  const getByOrderNumber = async (orderNumber) => {
    setSearchType(searchTypes.orderNumber);
    const result = await OrdersService.getByOrderNumber(orderNumber);
    if (result?.order) {
      setFoundOrders([result.order]);
      setNumPages(1);
    } else {
      setFoundOrders([]);
      setNumPages(0);
    }
    setLoading(false);
  };

  const getByEmail = async (email, pageInput = 1) => {
    setCurrentPage(pageInput);
    setSearchType(searchTypes.email);
    const result = await OrdersService.getByEmail(email, pageInput);
    if (result.orders.results) {
      setFoundOrders(result.orders.results);
      setNumPages(Math.ceil(result.orders.total / result.orders.limit));
    } else {
      setFoundOrders([]);
      setNumPages(0);
    }
    setLoading(false);
  };

  const getStores = async () => {
    const result = await Stores.getStores();
    setStoreList(result);
    setSelectedStore(StoreUtility.getLocationStorage());
    return result;
  };

  const getByStore = async (pageInput = 1) => {
    setCurrentPage(pageInput);
    setSearchType(searchTypes.allStoreOrders);
    const result = await OrdersService.getByStoreKey(selectedStore.key, pageInput);
    if (result.orders.results) {
      setFoundOrders(result.orders.results);
      setNumPages(Math.ceil(result.orders.total / result.orders.limit));
    } else {
      setFoundOrders([]);
      setNumPages(0);
    }
    setLoading(false);
  };
  const searchInput = (event) => {
    event.preventDefault();
    setLoading(true);
    if (orderInput.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)) {
      getByEmail(orderInput);
    } else {
      getByOrderNumber(orderInput);
    }
  };

  const handleInput = (event) => {
    setOrderInput(event.target.value);
  };

  const updateSelectedStore = (store) => {
    setSelectedStore(store);
  };

  const handlePageChange = (event, pageValue, searchTypeInput) => {
    switch (searchTypeInput) {
      case searchTypes.email:
        getByEmail(orderInput, pageValue);
        break;
      case searchTypes.allStoreOrders:
        getByStore(pageValue);
        break;
      case searchTypes.todaysStoreOrders:
        getTodaysStoreOrders(pageValue);
        break;
      default:
    }
    document.getElementById('OrdersList').scrollTo(0, 0);
  };

  useEffect(() => {
    getStores();
  }, [storeList.length]);

  useEffect(() => {
    getTodaysStoreOrders();
  }, []);

  return (
    <Container disableGutters sx={{ width: '100%', mt: 12, mb: 10 }}>
      <Grid container justifyContent="center" alignItems="center" sx={{ p: 1.5 }}>
        <Grid item sm={6}>
          <form onSubmit={searchInput}>
            <TextField
              InputProps={{
                endAdornment: (
                  <Button
                    onClick={searchInput}
                    color="primary"
                    variant="contained"
                    disabled={!orderInput}
                  >
                    {' '}
                    {loading ? (
                      <CircularProgress color="secondary" size={25} />
                    ) : (
                      <SearchIcon />
                    )}{' '}
                  </Button>
                ),
              }}
              placeholder={copyText.Orders.Orders.searchById}
              onChange={handleInput}
              variant="outlined"
              fullWidth
            />
          </form>
        </Grid>
        <Grid item>
          <Select
            value={selectedStore?.id}
            label={copyText.Location.select}
            variant="standard"
            disableUnderline
          >
            {storeList.map((store) => (
              <MenuItem onClick={() => updateSelectedStore(store)} key={store.id} value={store.id}>
                {store.name[lang]}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item>
          <Button onClick={() => getByStore()} color="primary" variant="contained">
            {copyText.Orders.Orders.storeOrders}
          </Button>
        </Grid>
      </Grid>
      <Divider />
      <Grid container columns={16} sx={{ p: 1 }}>
        <Grid item xs={16} sm={6}>
          <OrderList orders={foundOrders} />
          {numPages > 1 && (
            <Pagination
              page={currentPage}
              count={numPages}
              onChange={(event, pageValue) => handlePageChange(event, pageValue, searchType)}
            />
          )}
        </Grid>
        <Grid item xs={0} sm={0.25} alignItems="center">
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={16} sm={9.75}>
          <Grid container alignItems="center">
            <Grid item sm={5}>
              {/* TODO: Fix usage of H1 tags */}
              <Typography variant="h5" component="h1">
                {copyText.Orders.Orders.orderDetails}
              </Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography variant="subtitle1" align="right">
                {copyText.Orders.Orders.orderNumber}
              </Typography>
            </Grid>
            <Grid item sm={3}>
              <Typography align="center" sx={{ fontStyle: 'bold', fontWeight: 'bold' }}>
                {selectedOrder?.orderNumber}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <List
            sx={{
              position: 'relative',
              overflow: 'scroll',
              maxHeight: '100vh',
              mb: 4,
              p: 1,
            }}
          >
            {selectedOrder ? (
              <>
                <OrderDetails />
                <CustomerDetails />
                <OrderItems />
                <OrderTotals numItems={2} />
                <RefundInfo />
                <ReturnBox />
              </>
            ) : null}
          </List>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Orders;
