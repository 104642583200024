import { lang } from 'language';
import cartShipping from 'dataAccess/api/cart.shipping.ts';
import shippingContext from './shippingContext';

export const addCartToHistory = (newCart) => {
  if (sessionStorage.getItem('cartHistory') === null) {
    const cartHistory = [{ status: 'active', cart: newCart.id }];
    sessionStorage.setItem('cartHistory', JSON.stringify(cartHistory));
  } else {
    let cartHistory = sessionStorage.getItem('cartHistory');
    cartHistory = JSON.parse(cartHistory);
    cartHistory.push({ status: 'active', cart: newCart.id });
    sessionStorage.setItem('cartHistory', JSON.stringify(cartHistory));
  }
};

export const moveToTopOfHistory = (cartId) => {
  // Get the cartHistory array from session storage
  const cartHistory = JSON.parse(sessionStorage.getItem('cartHistory'));
  // Find the index of the cart with the specified cartId
  const cartIndex = cartHistory.findIndex((item) => item.cart === cartId);

  // If the cart is found, remove it from its current position and insert it at the end of the array
  if (cartIndex !== -1) {
    const cartToMove = cartHistory.splice(cartIndex, 1)[0];
    cartHistory.push(cartToMove);

    // Update the cartHistory array in session storage
    sessionStorage.setItem('cartHistory', JSON.stringify(cartHistory));
  }
};

export const updateProcessedCart = (cartId) => {
  // Get the cartHistory array from session storage
  const cartHistory = JSON.parse(sessionStorage.getItem('cartHistory')) || [];

  // Find the index of the array of objects that contains the item with itemId
  const cartItemIndex = cartHistory.findIndex((item) => item.cart === cartId);

  // If the array of objects is found, update the status property of that object to 'processed'
  if (cartItemIndex !== -1) {
    cartHistory[cartItemIndex].status = 'processed';

    // Update the cartHistory array in session storage
    sessionStorage.setItem('cartHistory', JSON.stringify(cartHistory));
  }
};

export const getMostRecentCart = () => {
  if (sessionStorage.getItem('cartHistory') !== null) {
    let cartHistory = sessionStorage.getItem('cartHistory');
    // cartHistory is stored as a string, needs to be parsed before indexing
    cartHistory = JSON.parse(cartHistory);
    // cartHistory.at(-1) gets last item in the array
    return cartHistory.at(-1).cart;
  }
  return null;
};

export const formatShippingMethods = (shippingMethod) => {
  const contextShippingMethod = {
    key: shippingMethod.custom?.fields?.netsuite_shipping_name,
    name: shippingMethod.name[lang],
    price: shippingMethod.money?.centAmount,
    offerRemovalOption: shippingMethod.custom?.fields?.mattress_removal,
    storeKey: shippingMethod.custom?.fields?.storeKey,
  };
  return contextShippingMethod;
};

export const getShippingMethodSkus = (cart, shippingMethod) => {
  const skus = cart.lineItems
    .filter((item) => shippingMethod.custom?.fields?.lineItemsIds.includes(item.id))
    .map((item) => item.variant.sku);
  return skus;
};

export const loadOldCartShippingMethods = (cart) => {
  shippingContext.removeShippingMethodStorage();
  const emptyStorage = [];
  const availableShippingMethods = cartShipping.getAllShippingMethods();

  cart.customLineItems?.forEach((shippingMethod) => {
    // if custom lineitem is a shipping method
    if (availableShippingMethods.includes(shippingMethod.custom?.fields?.netsuite_shipping_name)) {
      // populate array of skus
      const skus = getShippingMethodSkus(cart, shippingMethod);
      // load each sku into shipping context w/ shipping method
      skus.forEach((sku) => {
        const contextShippingMethod = formatShippingMethods(shippingMethod);
        shippingContext.updateShippingContext(emptyStorage, contextShippingMethod, sku);
      });
    }
  });
};
