import React, { useState, useEffect } from 'react';
import { Box, MenuItem, Select } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import locationUtil from 'utils/storeLocation';
import Stores from 'dataAccess/api/stores.ts';
import haversine from 'utils/haversineCalc';
import { copyText, lang } from 'language';

const Location = () => {
  const { oktaAuth } = useOktaAuth();
  const [storeList, setStoreList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [locationLoading, setLocationLoading] = useState(true);
  const [userLocation, setUserLocation] = useState();
  const [selectedStore, setSelectedStore] = useState('' || null);

  //  This calls the store api and retrieves the list of all the store locations.
  const getStores = async () => {
    const result = await Stores.getStores();
    return result;
  };
  //  This is retrieving the users current location
  const locate = async () => {
    const storesResult = await getStores();
    const location = await locationUtil.getLocation();
    setUserLocation(location);
    setStoreList(storesResult);
    setLocationLoading(false);
  };

  const selectStore = async () => {
    const sessionStoreData = locationUtil.getLocationStorage();
    if (sessionStoreData) {
      setSelectedStore(sessionStoreData);
      setLoading(false);
    } else if (userLocation && storeList) {
      let closest = null;
      let bestLocationSoFar = null;
      storeList.forEach((store) => {
        const geoCoordinates = store.supplyChannels[0]?.obj.geoLocation?.coordinates;
        const geoCoordObj = {
          latitude: geoCoordinates[1],
          longitude: geoCoordinates[0],
        };

        // function for calculating distance between geocoordinates
        const distance = haversine(geoCoordObj, userLocation);
        if (closest == null || distance < closest) {
          bestLocationSoFar = store;
          closest = distance;
          setLoading(false);
        }
      });
      locationUtil.setLocationStorage(bestLocationSoFar);
      setSelectedStore(bestLocationSoFar);
    }
  };
  const updateSelectedStore = (store) => {
    locationUtil.setLocationStorage(store);
    setSelectedStore(store);
  };
  useEffect(() => {
    locate();
    getStores();
  }, [oktaAuth.authStateManager._authState]);

  useEffect(() => {
    selectStore();
  }, [locationLoading]);

  if (loading || locationLoading) {
    return <Box>{copyText.Location.loading}</Box>;
  }

  return (
    <Box sx={{ mr: 3 }}>
      <Select
        value={selectedStore.id}
        sx={{ color: 'white' }}
        label={copyText.Location.select}
        variant="standard"
        disableUnderline
      >
        {storeList.map((store) => (
          <MenuItem key={store.id} onClick={() => updateSelectedStore(store)} value={store.id}>
            {store.name[lang]}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default Location;
