import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Button,
  ListItem,
  Box,
  TextField,
  Tooltip,
  Chip,
  List,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import ShippingAddress from 'dataAccess/api/cart.addresses.ts';
import { copyText } from 'language';
import { useCart, useSetCart } from 'contexts';
import { InfoOutlined } from '@mui/icons-material';
import storeLocation from 'utils/storeLocation';
import TextLoader from 'components/TextLoader';
import usStateCode2Char from 'utils/UsStateCodes';

const ShippingInfoInput = () => {
  const lineHeight = 1;
  const cart = useCart();
  const setCart = useSetCart();
  const [open, setOpen] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [disableUseStoreAddress, setDisableUseStoreAddress] = useState(false);
  const [loading, setLoading] = useState(false);
  // TODO: remove the separate email input, it should be part of the shipping data input
  const handleClose = (event, reason) => {
    // Disable closing modal from clicking backdrop
    if (reason && reason === 'backdropClick') {
      return;
    }
    setOpen(false);
    setShowErrorMessage(false);
  };
  const handleUseStoreAddress = async () => {
    setLoading(true);
    const storeData = storeLocation.getLocationStorage();
    const storeAddress = storeData.supplyChannels[0].obj.address;
    const address = {
      firstName: 'Purple',
      lastName: 'Innovation',
      streetAddress: storeAddress.streetName,
      additionalStreetInfo: storeAddress.streetNumber,
      postalCode: storeAddress.postalCode,
      city: storeAddress.city,
      state: storeAddress.state,
      country: storeAddress.country,
      email: 'noEmail@email.com',
      phone: 'None',
    };
    const result = await ShippingAddress.setShippingAddress(cart?.id, address);
    setCart(result);
    setLoading(false);
    handleClose();
  };

  const checkShippingMethods = () => {
    const filteredShipping = cart.customLineItems.filter(
      (lineItem) =>
        lineItem.name['en-US'] === 'Standard Shipping' ||
        lineItem.name['en-US'] === 'In-Home Setup' ||
        lineItem.name['en-US'] === 'FEDEX 2 DAY',
    );
    if (filteredShipping.length > 0) {
      setDisableUseStoreAddress(true);
    } else {
      setDisableUseStoreAddress(false);
    }
  };

  useEffect(() => {
    checkShippingMethods();
  }, [cart.customLineItems]);

  const [formData, setFormData] = useState({
    firstName: cart?.shippingAddress?.firstName || '',
    lastName: cart?.shippingAddress?.lastName || '',
    streetAddress: cart?.shippingAddress?.streetName || '',
    postalCode: cart?.shippingAddress?.postalCode || '',
    city: cart?.shippingAddress?.city || '',
    state: cart?.shippingAddress?.state || '',
    country: cart?.shippingAddress?.country || 'US',
    email: cart?.shippingAddress?.email || '',
    phone: cart?.shippingAddress?.phone || '',
  });

  const formatFormValue = (value, key) => {
    let newValue = value;
    if (key === 'firstName' || key === 'lastName' || key === 'city') {
      newValue = value.replace(/(?:^|\s)\S/g, (a) => {
        return a.toUpperCase();
      });
    }
    return newValue;
  };

  const updateSelectValue = (key, value) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const updateForm = (e) => {
    let value = e.target.value;
    const key = e.target.getAttribute('name');
    value = formatFormValue(value, key);

    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const updateCart = async () => {
    try {
      setLoading(true);
      const result = await ShippingAddress.setShippingAddress(cart?.id, formData);
      setCart(result);
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);

      setShowErrorMessage(true);
    }
  };
  return (
    <>
      <ListItem alignItems="center" sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box display="flex">
          {cart?.shippingAddress ? (
            <TaskAltIcon color="success" />
          ) : (
            <HighlightOffIcon color="error" />
          )}
          <Typography marginLeft={2} component="p" sx={{ fontSize: 16, fontWeight: 'bold' }}>
            {copyText.Cart.CartTools.shippingAddress}
          </Typography>
        </Box>
        <Typography>
          <Button onClick={handleClickOpen}>
            {cart?.shippingAddress ? copyText.Cart.CartTools.edit : copyText.Cart.CartTools.add}
          </Button>
        </Typography>
      </ListItem>
      {cart?.shippingAddress && (
        <List sx={{ m: 1 }}>
          <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="p" component="strong" fontSize={14} sx={{ lineHeight }}>
              {copyText.Cart.CartTools.customerName}
            </Typography>
            <Typography fontSize={14} sx={{ lineHeight }}>
              {`${cart?.shippingAddress.firstName} ${cart?.shippingAddress.lastName}`}
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="p" component="strong" fontSize={14} sx={{ lineHeight }}>
              {copyText.Cart.CartTools.streetAddressOnly}
            </Typography>
            <Typography fontSize={14} sx={{ lineHeight }}>
              {cart?.shippingAddress.streetName}
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} sx={{ lineHeight }} variant="p" component="strong">
              {copyText.Cart.CartTools.cityStateZip}
            </Typography>
            <Typography fontSize={14} sx={{ lineHeight }}>
              {`${cart?.shippingAddress.city}, ${cart?.shippingAddress.state} ${cart?.shippingAddress.postalCode}`}
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} sx={{ lineHeight }} variant="p" component="strong">
              {copyText.Cart.CartTools.email}
            </Typography>
            <Typography fontSize={14} sx={{ lineHeight }}>
              {cart?.customerEmail}
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} sx={{ lineHeight }} variant="p" component="strong">
              {copyText.Cart.CartTools.phone}
            </Typography>
            <Typography fontSize={14} sx={{ lineHeight }}>
              {cart?.shippingAddress.phone}
            </Typography>
          </ListItem>
        </List>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          id="alert-dialog-title"
          sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'lightGrayBG' }}
        >
          {copyText.Cart.CartTools.shippingAddress}
          <Box display="flex" alignItems="center">
            <Button
              onClick={handleUseStoreAddress}
              disabled={disableUseStoreAddress || loading === true}
            >
              <TextLoader
                text={copyText.Cart.CartTools.useStoreAddress}
                loading={loading}
                size={20}
              />
            </Button>
            <Tooltip disableFocusListener title={copyText.Cart.CartTools.pickupCarryOutOnly}>
              <InfoOutlined sx={{ color: 'gray' }} />
            </Tooltip>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container rowSpacing={{ sm: 2 }} columnSpacing={{ sm: 2 }}>
            <Grid item sm={6} sx={{ mb: 3, mt: 3 }}>
              <TextField
                label={copyText.Cart.CartTools.firstName}
                placeholder={copyText.Cart.CartTools.customerFirstName}
                onChange={updateForm}
                name="firstName"
                fullWidth
                defaultValue={cart?.shippingAddress?.firstName}
              />
            </Grid>
            <Grid item sm={6} sx={{ mb: 3, mt: 3 }}>
              <TextField
                label={copyText.Cart.CartTools.lastName}
                placeholder={copyText.Cart.CartTools.customerLastName}
                onChange={updateForm}
                name="lastName"
                fullWidth
                defaultValue={cart?.shippingAddress?.lastName}
              />
            </Grid>
            {/* <Grid item sm={6}>
              <TextField
              label={copyText.Cart.CartTools.phoneNumber}
              placeholder={copyText.Cart.CartTools.phoneNumber}
              onChange={updateForm}
              name="phoneNumber"
              fullWidth
              />
            </Grid> */}
            <Grid item sm={8}>
              <TextField
                label={copyText.Cart.CartTools.streetAddressSteNumber}
                placeholder={copyText.Cart.CartTools.streetAddressSteNumber}
                onChange={updateForm}
                name="streetAddress"
                fullWidth
                defaultValue={cart?.shippingAddress?.streetName}
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                label={copyText.Cart.CartTools.city}
                placeholder={copyText.Cart.CartTools.city}
                onChange={updateForm}
                name="city"
                fullWidth
                defaultValue={cart?.shippingAddress?.city}
              />
            </Grid>
            <Grid item sm={4}>
              <FormControl fullWidth>
                <InputLabel id="discount-select-product">
                  {copyText.Cart.CartTools.state}
                </InputLabel>
                <Select
                  label={copyText.Cart.CartTools.state}
                  placeholder={copyText.Cart.CartTools.state}
                  fullWidth
                  defaultValue={cart?.shippingAddress?.state || ''}
                >
                  {usStateCode2Char.map((code) => {
                    return (
                      <MenuItem
                        onClick={() => updateSelectValue('state', code)}
                        value={code}
                        key={code}
                      >
                        {code}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={4}>
              <TextField
                label={copyText.Cart.CartTools.country}
                placeholder={copyText.Cart.CartTools.country}
                onChange={updateForm}
                name="country"
                fullWidth
                disabled
                defaultValue="US"
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                label={copyText.Cart.CartTools.postalCode}
                placeholder={copyText.Cart.CartTools.postalCode}
                onChange={updateForm}
                name="postalCode"
                fullWidth
                defaultValue={cart?.shippingAddress?.postalCode}
              />
            </Grid>
            <Grid item sm={6} sx={{ mt: 3 }}>
              <TextField
                label={copyText.Cart.CartTools.email}
                placeholder={copyText.Cart.CartTools.email}
                onChange={updateForm}
                name="email"
                fullWidth
                defaultValue={cart?.shippingAddress?.email}
              />
            </Grid>
            <Grid item sm={6} sx={{ mt: 3 }}>
              <TextField
                label={copyText.Cart.CartTools.phoneNumber}
                placeholder={copyText.Cart.CartTools.phoneNumber}
                onChange={updateForm}
                name="phone"
                fullWidth
                defaultValue={cart?.shippingAddress?.phone}
              />
            </Grid>
          </Grid>
          <Box mt={4} display="flex" justifyContent="center">
            <Typography sx={{ fontSize: 14 }}>
              {copyText.Cart.CartTools.validAddressForWarranty}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 3, alignItems: 'center', backgroundColor: 'lightGrayBG' }}>
          <Grid item sm={16}>
            <Box display="flex" alignItems="center">
              <Chip
                color={showErrorMessage ? 'error' : 'secondary'}
                label={
                  showErrorMessage ? copyText.Cart.CartTools.invalid : copyText.Cart.CartTools.valid
                }
              />
            </Box>
          </Grid>
          <Button variant="outlined" sx={{ marginRight: 1 }} onClick={handleClose}>
            <Typography variant="h3" component="span" sx={{ pr: 2, pl: 2, pt: 1, pb: 1 }}>
              {copyText.App.close}
            </Typography>
          </Button>
          {/* TODO: We need to figure out what we are going to do for shipping. */}
          <Button variant="contained" onClick={updateCart} disabled={loading}>
            <Typography
              sx={{ pr: 2, pl: 2, pt: 1, pb: 1 }}
              color="white"
              variant="h3"
              component="span"
            >
              <TextLoader text={copyText.Cart.CartTools.update} loading={loading} size={10} />
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShippingInfoInput;
