import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import purpleLogo from 'media/images/BETA_purple_logo_login.svg';
import { copyText } from 'language';
import { Button, Grid, Typography } from '@mui/material';
import LoginStyles from './Login.styles';

const Login = () => {
  const { oktaAuth } = useOktaAuth();
  const login = async () => oktaAuth.signInWithRedirect();

  return (
    <LoginStyles>
      <Grid container spacing={{ xs: 5, md: 5 }} sx={{ textAlign: 'center' }}>
        <Grid
          item
          xs={12}
          sx={{
            mt: {
              md: 10,
              sm: -20,
              xs: 5,
            },
            mb: { md: 10, sm: 20, xs: 5 },
          }}
        >
          <img src={purpleLogo} alt="logo" />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h1" sx={{ fontSize: '3rem' }}>
            {copyText.Login.greeting}
          </Typography>
          <Typography variant="h2" sx={{ color: 'white', fontSize: '2.25rem', lineHeight: 1 }}>
            {copyText.Login.instructions}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={{ xs: 5, md: 5 }}>
            <Grid item xs={12}>
              <Button
                data-testid="login-button"
                onClick={login}
                variant="contained"
                size="large"
                sx={{ height: '3rem', fontSize: '1.75rem', p: 5 }}
              >
                {copyText.Login.loginBtn}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography component="p" sx={{ fontSize: '1.25rem' }}>
                {copyText.Login.redirect}
                <strong> onpurple.okta.com</strong>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </LoginStyles>
  );
};

export default Login;
