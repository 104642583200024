import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  DialogActions,
} from '@mui/material';
import { copyText } from 'language';

const ReturnModal = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [returnOptions, setReturnOptions] = useState();

  // SUNSET: Get this data preferably from an API endpoint so it is always up-to-date
  const getReturnOptions = () => {
    return [
      {
        label:
          'Purple Harmony™ Pillow, Purple Pillow®, Purple TwinCloud™ Pillow, Purple Cloud™ Pillow, Kid’s Purple Pillow®, Purple Pillow Booster.',
        title: 'Pillows',
        href: 'https://survey.comfort-research.org/jfe/form/SV_6nk5tF3mUiKiELk',
      },
      {
        label: 'All Purple Seat Cushions',
        title: 'Seat Cushions',
        href: 'https://survey.comfort-research.org/jfe/form/SV_2agjLXfl8FEz5KC',
      },
      {
        label:
          'All Purple Sheet Sets, All Purple Pillowcase Sets, Purple Mattress Protector®, Purple Duvet®, and the Bearaby® Weighted Blanket',
        title: 'Bedding',
        href: 'https://survey.comfort-research.org/jfe/form/SV_agSU92KQnnO1pfo',
      },
      {
        label: 'Sleepy Jones + Purple Pajamas',
        title: 'Bedding + Pajamas',
        href: 'https://purple.com/returns/30-days',
      },
    ];
  };

  const populateReturnOptions = () => {
    const options = getReturnOptions();
    setReturnOptions(options);
  };

  useEffect(() => {
    populateReturnOptions();
  }, []);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Button variant="outlined" onClick={openModal}>
        {copyText.Orders.ReturnBox.return}
      </Button>
      <Dialog
        fullWidth
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{copyText.Orders.ReturnInfo.modalTitle}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {returnOptions &&
              returnOptions.map((option, ind) => {
                const key = option.title + ind;
                return (
                  <Grid item key={key} xs={12} container alignItems="center">
                    <Grid item xs={8}>
                      <Typography component="h3" sx={{ fontSize: '2rem' }}>
                        {option.title}
                      </Typography>
                      <Typography variant="caption" component="p">
                        {option.label}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        href={option.href}
                        target="_blank"
                        rel="noreferrer"
                        component="a"
                        variant="outlined"
                        size="large"
                      >
                        <Typography variant="p">
                          {copyText.Orders.ReturnInfo.startRefund}
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>{copyText.Orders.ReturnInfo.cancel}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReturnModal;
