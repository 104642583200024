import axios from 'axios';
import getAccessToken from 'utils/getAccessToken';
import { AddItemToCartParams, UpdateItemQuantityParams } from './interfaces/cartEndpointInterfaces';

/**
 * addToCart - Adds a line item to a given cart by sku
 * @param params cartId, sku, quantity?
 * @returns An updated cart or undefined
 * @link https://onpurple.github.io/carts-ms/#/paths/~1{cartId}~1line-items/post
 */
const addItemToCart = async (params: AddItemToCartParams) => {
  const oktaToken = getAccessToken();
  const hasRequiredParams = !!params && !!params.cartId && !!params.sku;
  if (hasRequiredParams) {
    const uri = `${process.env.REACT_APP_MS_URL}/carts/${params.cartId}/line-items`;
    const reqBody = [
      {
        sku: params.sku,
        quantity: params.quantity ?? 1,
      },
    ];
    const config = {
      headers: {
        role: 'agent',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oktaToken}`,
      },
    };
    const response = await axios.post(uri, JSON.stringify(reqBody), config);

    return response.data;
  }

  throw new Error('Adding to cart requires: sku, cartId');
};

/**
 * updateItemQuantityInCart - Updates the quantity of an item in the cart
 * @param params cartId, lineItemId, quantity
 * @returns an updated cart or undefined
 * @link https://onpurple.github.io/carts-ms/#/paths/~1line-items~1{lineItemId}~1quantity/patch
 */
const updateItemQuantityInCart = async (params: UpdateItemQuantityParams) => {
  const hasRequiredParams = !!params && !!params.cartId && !!params.lineItemId && !!params.quantity;
  const oktaToken = getAccessToken();

  if (hasRequiredParams) {
    const uri = `${process.env.REACT_APP_MS_URL}/carts/${params.cartId}/line-items/${params.lineItemId}/quantity`;
    const reqBody = {
      quantity: params.quantity,
    };

    const config = {
      headers: {
        role: 'agent',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oktaToken}`,
      },
    };
    const response = await axios.patch(uri, JSON.stringify(reqBody), config);
    return response.data;
  }

  throw new Error('Updating quantity requires: lineItemId, cartId, quantity');
};

/**
 * removeItemFromCart
 * @param cartId the uuid of the cart
 * @param lineItemId the CT id of the line item
 * @returns An updated Cart or undefined
 * @link https://onpurple.github.io/carts-ms/#/paths/~1line-items~1{lineItemId}/delete
 */
const removeItemFromCart = async (cartId: string, lineItemId: string) => {
  const hasRequiredParams = !!cartId && !!lineItemId;
  const oktaToken = getAccessToken();

  if (hasRequiredParams) {
    const uri = `${process.env.REACT_APP_MS_URL}/carts/${cartId}/line-items/${lineItemId}`;
    const config = {
      headers: {
        role: 'agent',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oktaToken}`,
      },
    };
    const response = await axios.delete(uri, config);

    return response.data;
  }

  throw new Error('Removing an item requires: cartId, lineItemId');
};

export default {
  addItemToCart,
  updateItemQuantityInCart,
  removeItemFromCart,
};
