import cartShipping from 'dataAccess/api/cart.shipping.ts';
import locationUtils from './storeLocation';

const setShippingMethodStorage = (shippingMethods) => {
  sessionStorage.setItem('shipping-methods', JSON.stringify(shippingMethods));
};

const removeShippingMethodStorage = () => {
  sessionStorage.removeItem('shipping-methods');
};
const getShippingMethodStorage = () => {
  const shippingMethods = JSON.parse(window.sessionStorage.getItem('shipping-methods')) || [];
  return shippingMethods;
};

/**
 * @description finds and removes the sku from shipping context (usually occurs when there's a removal from the cart).
 * each shipping method contains an array of skus.
 * If, after removing the sku from the shipping method's list of skus, the sku list is empty,
 * the shipping method itself is removed from the shipping context
 * @param {string} sku the sku to be removed
 * @param {shippingMethods} shippingMethods a reference to shippingMethods
 * @returns an updated shipping context object for overwriting the now outdated shippingContext
 */
const removeLineItemShipping = (sku, shippingMethods) => {
  let contextCopy = shippingMethods;
  const indexOfSku = contextCopy.findIndex((method) => method.skus.includes(sku));
  if (indexOfSku === -1) {
    return contextCopy;
  }
  contextCopy[indexOfSku].skus = contextCopy[indexOfSku].skus.filter((s) => s !== sku);
  contextCopy = contextCopy.filter((method) => method.skus.length > 0);
  return contextCopy;
};
/**
 * @description filters all shipping methods available to the cart and returns the shipping methods for the supplied sku / lineItem
 * @param {Array} shippingMethods a list of all cart shipping methods (for every product)
 * @param {string} sku the sku used for filtering
 * @returns
 */
const parseShippingMethods = (shippingMethods, sku) => {
  const result = shippingMethods.filter((method) => method.groups[0]?.products.includes(sku));
  return result;
};

/**
 * @description finds the store key associated with the sku
 * @param {shippingMethods} shippingMethods a reference to shippingMethods
 * @param {string} sku the sku to be found
 * @returns store key (ex: UT-01) or null
 */
const getSkuStore = (shippingMethods, sku) => {
  const contextCopy = shippingMethods;
  const indexOfSku = contextCopy.findIndex((method) => method.skus.includes(sku));
  if (indexOfSku !== -1) {
    return contextCopy[indexOfSku].storeKey;
  }
  return null;
};
/**
 * @description finds the provided sku in shippingMethods. If remove is true, removes it from the list in which it's found
 * @param {shippingMethods} shippingMethods a reference to shippingMethods
 * @param {boolean} remove indicator whether to remove it from it's current list
 * @param {string} sku the sku to be found and possibly removed
 * @returns an updated instance of the shippingMethods that will eventually overwrite the now outdated shippingMethods
 */
const checkSkuInContext = (shippingMethods, remove = false, sku) => {
  let contextCopy = shippingMethods;
  const indexOfSku = contextCopy.findIndex((method) => method.skus.includes(sku));
  if (indexOfSku === -1) {
    return contextCopy;
  }
  if (remove) {
    contextCopy[indexOfSku].skus = contextCopy[indexOfSku].skus.filter((s) => s !== sku);
    if (contextCopy[indexOfSku].skus.length === 0) {
      contextCopy = contextCopy.splice(indexOfSku, 1);
    }
  }
  return contextCopy;
};

/**
 * @description adds the given sku to context where necessary.
 * Prior to calling this method, if that sku was in context elsewhere, it should have been removed
 * by calling checkSkuInContext
 * @param {shippingMethods} shippingMethods a reference to shippingMethods
 * @param {Object} newShippingMethod
 * @param {string} newSku
 * @returns an updated shippingMethods used to overwrite the existing shippingMethods
 */
const addSku = (shippingMethods, newShippingMethod, newSku) => {
  const contextCopy = shippingMethods;
  const shippingMethodIndex = contextCopy.findIndex(
    (method) => method.key === newShippingMethod.key,
  );
  // method not already in context, add method
  if (
    shippingMethodIndex === -1 ||
    (newShippingMethod.name === 'Pick Up' &&
      newShippingMethod.storeKey !== contextCopy[shippingMethodIndex].storeKey)
  ) {
    const contextShippingMethod = {
      key: newShippingMethod.key,
      name: newShippingMethod.name,
      cost: newShippingMethod.price.centAmount,
      needsRemoval: newShippingMethod.offerRemovalOption,
      skus: [newSku],
      storeKey: newShippingMethod.storeKey,
    };
    contextCopy.push(contextShippingMethod);
  } else {
    // shipping method already in context, add sku to skus array
    contextCopy[shippingMethodIndex].skus.push(newSku);
  }
  return contextCopy;
};

/**
 * @description a driver method for updating shippingMethods. calls other methods in this file to execute updates
 * @param {shippingMethods} shippingMethods a reference to the shippingMethods
 * @param {object} newShippingMethod the new shipping method to be added to context
 * @param {string} newSku the sku to be added
 * @returns an updated instance of shippingMethods
 */
const updateShippingContext = async (
  shippingMethods,
  newShippingMethod,
  newSku,
  cartId = null,
  updateCart = false,
) => {
  let contextCopy = shippingMethods;
  contextCopy = checkSkuInContext(shippingMethods, true, newSku);
  contextCopy = addSku(shippingMethods, newShippingMethod, newSku);
  setShippingMethodStorage(contextCopy);
  if (updateCart) {
    await cartShipping.setShippingMethods(contextCopy, cartId);
  }
  return contextCopy;
};

/**
 * @description finds the sku in shippingMethods context via checkSkuInContext and removes it, calls addSku to add new instance of sku
 * @param {shippingMethods} shippingMethods a reference to the shippingMethods
 * @param {string} sku the sku to be updated
 * @param {string} storeKey the store key to be applied
 * @returns updated instance of shippingMethods
 */
const setSkuStore = (sku, storeKey, cartId) => {
  const contextCopy = getShippingMethodStorage();
  const index = contextCopy.findIndex((method) => method.skus.includes(sku));
  const contextShippingMethod = {
    key: contextCopy[index].key,
    name: contextCopy[index].name,
    price: { centAmount: contextCopy[index].cost },
    offerRemovalOption: contextCopy[index].needsRemoval,
    storeKey,
  };
  const result = updateShippingContext(contextCopy, contextShippingMethod, sku, cartId, false);
  return result;
};

/**
 * @description this method is used to add a default shipping method when a new line item is added to the cart
 * @param {Cart} cart
 * @param {String} sku
 * @returns null, if line item is already in the cart, or an updated cart
 */
const handleNewLineItem = async (cart, sku) => {
  const contextCopy = getShippingMethodStorage();

  const indexOfSku = contextCopy.findIndex((method) => method.skus.includes(sku));
  if (indexOfSku !== -1) {
    // product already in cart, do not update shipping method
    return null;
  }
  const contextShippingMethod = {
    key: 'FEDEX_GROUND',
    name: 'Standard Shipping',
    price: { centAmount: 0 },
    offerRemovalOption: false,
    storeKey: locationUtils.getStoreKey(),
  };
  const newContext = await updateShippingContext(contextCopy, contextShippingMethod, sku);
  const result = await cartShipping.setShippingMethods(newContext, cart.id);
  return result;
};

export default {
  removeLineItemShipping,
  parseShippingMethods,
  updateShippingContext,
  addSku,
  getSkuStore,
  setSkuStore,
  handleNewLineItem,
  getShippingMethodStorage,
  setShippingMethodStorage,
  removeShippingMethodStorage,
};
