import axios from 'axios';
import getAccessToken from 'utils/getAccessToken';

const getPaymentById = async (orderId: string) => {
  try {
    const accessToken = getAccessToken();
    const uri = `${process.env.REACT_APP_PIM_BASE_URL}/payments/${orderId}`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.get(uri, config);
    return result.data;
  } catch (error) {
    return 'no order found';
  }
};

export default { getPaymentById };
