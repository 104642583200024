import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Select,
  MenuItem,
  DialogActions,
  InputLabel,
} from '@mui/material';
import { func, string, shape, bool } from 'prop-types';
import { copyText } from 'language';
import LocationHooks from 'utils/LocationHooks';

const OrderFilterModal = ({
  modalOpenStatus,
  selectedStore,
  setSelectedStore,
  closeFilterModal,
  searchFunction,
}) => {
  const { useGetStoreNameByKey, locationList } = LocationHooks();

  const searchFilter = (event) => {
    searchFunction(event);
    closeFilterModal();
  };

  return (
    <Dialog open={modalOpenStatus}>
      <DialogTitle sx={{ backgroundColor: 'lightGrayBG', mb: 4 }} id="filter-dialog-title">
        {copyText.Layouts.SearchLayout.filters}
      </DialogTitle>
      <DialogContent>
        <InputLabel id="order-store-select">{copyText.Orders.Orders.storeSelect}</InputLabel>
        <Select
          value={selectedStore.key}
          label="order-store-select"
          variant="standard"
          disableUnderline
        >
          {locationList?.map((location) => (
            <MenuItem
              onClick={() => setSelectedStore(location)}
              key={location.key}
              value={location.key}
            >
              {useGetStoreNameByKey(location.key)}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={closeFilterModal}>
          {copyText.App.cancel}
        </Button>
        <Button variant="contained" color="primary" onClick={searchFilter}>
          {copyText.Orders.Orders.search}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderFilterModal;

OrderFilterModal.propTypes = {
  modalOpenStatus: bool,
  selectedStore: shape({ key: string }),
  setSelectedStore: func.isRequired,
  closeFilterModal: func.isRequired,
  searchFunction: func.isRequired,
};

OrderFilterModal.defaultProps = {
  modalOpenStatus: false,
  selectedStore: { key: '' },
};
