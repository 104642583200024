import { ChevronRight } from '@mui/icons-material';
import { Card, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useOldCart, useSetOldCart } from 'contexts';
import { copyText } from 'language';
import { shape, string } from 'prop-types';
import getDate from 'utils/getDate';

const CartHistoryItem = ({ cart }) => {
  const oldCart = useOldCart();
  const setOldCart = useSetOldCart();
  const handleListItemClick = (selectedCart) => {
    setOldCart(selectedCart);
  };

  const getCustomerName = () => {
    const firstName = cart.shippingAddress?.firstName || copyText.App.missing;
    const lastName = cart.shippingAddress?.lastName || copyText.App.missing;
    return `${firstName} ${lastName} ` || copyText.App.none;
  };
  const getLastUpdatedDate = () => {
    return getDate(cart.versionModifiedAt) || copyText.App.notFound;
  };
  return (
    <Card variant="outlined" sx={{ mb: 1, ml: 1 }} key={cart.id}>
      <ListItemButton
        selected={oldCart.id === cart.id}
        onClick={() => {
          handleListItemClick(cart);
        }}
      >
        <ListItemText
          primary={`${copyText.Cart.CartTools.customerName}: ${getCustomerName()}`}
          secondary={`${copyText.Cart.CartTools.dateModified}: ${getLastUpdatedDate()}`}
        />
        <Typography variant="subtitle2">{copyText.Orders.OrderList.details}</Typography>
        <ListItemIcon>
          <ChevronRight />
        </ListItemIcon>
      </ListItemButton>
    </Card>
  );
};

CartHistoryItem.propTypes = {
  cart: shape({
    id: string.isRequired,
    shippingAddress: shape({
      firstName: string,
      lastName: string,
    }),
    versionModifiedAt: string.isRequired,
  }).isRequired,
};

export default CartHistoryItem;
