import React from 'react';
import { Container, Grid, TextField, Button, CircularProgress } from '@mui/material';
import TableLayout from 'components/TableLayout/TableLayout';
import { arrayOf, func, number, string, shape, bool, node } from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import { copyText } from 'language';

const SearchLayout = (props) => {
  const {
    searchLabel,
    searchFunction,
    searchInput,
    handleInput,
    loading,
    columns,
    idColumn,
    rowData,
    numRows,
    handleListItemClick,
    handlePageChange,
    currentPage,
    setFilterToOpen,
    FilterComponent,
  } = props;

  return (
    <Container disableGutters>
      <Grid container justifyContent="center" alignItems="center" sx={{ p: 1.5, mt: 10 }}>
        <Grid xs={8} sx={{ mb: 2 }} item>
          <form onSubmit={searchFunction}>
            <TextField
              label={searchLabel || copyText.Layouts.SearchLayout.search}
              InputProps={{
                endAdornment: (
                  <Button
                    onClick={searchFunction}
                    color="primary"
                    variant="contained"
                    disabled={!searchInput}
                  >
                    {' '}
                    {loading ? (
                      <CircularProgress color="secondary" size={25} />
                    ) : (
                      <SearchIcon />
                    )}{' '}
                  </Button>
                ),
              }}
              placeholder={copyText.Layouts.SearchLayout.search}
              onChange={handleInput}
              variant="outlined"
              fullWidth
            />
          </form>
        </Grid>
        <Grid xs={2} sx={{ ml: 2, mb: 2 }} item>
          <Button onClick={setFilterToOpen} size="large" color="primary" variant="outlined">
            {copyText.Layouts.SearchLayout.filters}
          </Button>
        </Grid>
        <Grid xs={12} item>
          <TableLayout
            columns={columns}
            idColumn={idColumn}
            rowData={rowData}
            numRows={numRows}
            handleListItemClick={handleListItemClick}
            handlePageChange={handlePageChange}
            currentPage={currentPage}
          />
        </Grid>
      </Grid>
      {FilterComponent}
    </Container>
  );
};

export default SearchLayout;

SearchLayout.propTypes = {
  searchLabel: string,
  searchFunction: func.isRequired,
  searchInput: string,
  handleInput: func.isRequired,
  loading: bool,
  columns: arrayOf(string),
  idColumn: string.isRequired,
  rowData: arrayOf(
    shape({
      id: string,
      columns: arrayOf(string),
    }),
  ),
  numRows: number.isRequired,
  handleListItemClick: func.isRequired,
  handlePageChange: func.isRequired,
  currentPage: number,
  setFilterToOpen: func,
  FilterComponent: node,
};

SearchLayout.defaultProps = {
  searchLabel: '',
  searchInput: '',
  loading: false,
  columns: [],
  rowData: [],
  currentPage: 0,
  setFilterToOpen: () => {},
  FilterComponent: null,
};
