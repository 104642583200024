import React, { useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Card } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { copyText } from 'language';
import { useOrderPayment } from 'contexts/orders';
import toDollar from 'utils/toDollar';

const RefundInfo = () => {
  const orderPayment = useOrderPayment();
  const showRefund = () => {
    if (orderPayment) {
      return orderPayment.map((ele) => {
        const { transactions } = ele.payment;
        return (
          <AccordionDetails key={ele.payment.id}>
            <Typography>{ele.payment.paymentMethodInfo.method.replace('_', ' ')}</Typography>
            {transactions.map((transaction) => {
              return (
                <Card key={transaction.id} sx={{ mb: 1 }}>
                  <Typography>
                    {`${copyText.Orders.RefundInfo.transactionType}${transaction.type}`}
                  </Typography>
                  <Typography>
                    {`${copyText.Orders.RefundInfo.refundAmount}${toDollar(
                      transaction.amount.centAmount,
                    )}`}
                  </Typography>
                  <Typography>
                    {`${copyText.Orders.RefundInfo.transactionStatus}${transaction.state}`}
                  </Typography>
                </Card>
              );
            })}
          </AccordionDetails>
        );
      });
    }
    return null;
  };

  useEffect(() => {}, [orderPayment]);
  return (
    <Accordion sx={{ mb: 3 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{copyText.Orders.RefundInfo.paymentTransactions}</Typography>
      </AccordionSummary>
      {showRefund()}
    </Accordion>
  );
};

export default RefundInfo;
