import { useLocations } from 'contexts';
import { copyText, lang } from 'language';

const LocationHooks = () => {
  const locationList = useLocations();

  const useGetStoreNameByKey = (storeKey) => {
    if (storeKey) {
      const foundStore = locationList?.filter((store) => store.key === storeKey);
      return foundStore[0]?.name[lang] || copyText.Location.notAvailable;
    }
    return copyText.Location.notAvailable;
  };

  return { useGetStoreNameByKey, locationList };
};

export default LocationHooks;
