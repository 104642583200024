import React, { useState, useEffect } from 'react';
import { Button, Grid, TextField, Typography, Snackbar, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { copyText } from 'language';
import { useCart, useSetCart, useOktaClaims } from 'contexts';
import { addPromoCodeToCart } from 'utils/cart';
import TextLoader from 'components/TextLoader';
import CreatePromo from './components/CreatePromo';

const PromoCode = () => {
  const cart = useCart();
  const setCart = useSetCart();
  const [showPromoCode, setShowPromoCode] = useState(false);
  const [snackBarMessage, setSnackbarMessage] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const oktaClaims = useOktaClaims();

  const addPromoCode = async () => {
    try {
      setLoading(true);
      const updatedCart = await addPromoCodeToCart(cart, promoCode);
      setCart(updatedCart);
      setLoading(false);
      setSnackbarMessage(copyText.Cart.PromoCode.successfullyAddedToCart);
      setShowSnackbar(true);
    } catch (error) {
      setLoading(false);
      setSnackbarMessage(error.message);
      setShowSnackbar(true);
    }
  };

  const handlePromoCodeChange = (e) => {
    setPromoCode(e.target.value);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
  };

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
      <CloseIcon />
    </IconButton>
  );

  useEffect(() => {
    if (cart?.lineItems?.length === 0) {
      setShowPromoCode(false);
    }
  }, [cart]);
  return (
    <>
      <Grid container columns={16} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Grid item xs={13}>
          <Typography
            color="primary"
            align="left"
            component="h3"
            variant="h4"
            sx={{ fontWeight: 'bold', fontSize: 12 }}
          >
            {copyText.Cart.PromoCode.promoCode}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography align="right">
            <Button
              disabled={cart?.lineItems?.length === 0}
              sx={{ textTransform: 'none' }}
              onClick={() => setShowPromoCode(!showPromoCode)}
            >
              {showPromoCode ? copyText.Cart.PromoCode.hide : copyText.Cart.PromoCode.add}
            </Button>
          </Typography>
        </Grid>
      </Grid>
      {showPromoCode && (
        <Grid container sx={{ mb: 3, align: 'center' }} columns={16}>
          <Grid item xs={16}>
            <TextField
              fullWidth
              onChange={handlePromoCodeChange}
              placeholder={copyText.Cart.PromoCode.enterCode}
              InputProps={{
                endAdornment: (
                  <Button
                    fullWidth
                    onClick={addPromoCode}
                    disabled={promoCode.length === 0 || loading}
                    variant="contained"
                    color="primary"
                    sx={{ width: 20, textTransform: 'none' }}
                  >
                    <TextLoader text={copyText.Cart.PromoCode.apply} loading={loading} size={20} />
                  </Button>
                ),
              }}
            />
          </Grid>
          {oktaClaims?.mpos_manager && <CreatePromo />}
        </Grid>
      )}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackBarMessage}
        action={action}
      />
    </>
  );
};

export default PromoCode;
