import { func, number } from 'prop-types';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import copyText from 'language/enUS';

const QuantitySelect = ({ updateQuantity, numItem }) => {
  const qtyValues = Array.from({ length: 101 }, (_, i) => i);

  const handleUpdateQuantity = (e) => {
    updateQuantity(e.target.value);
  };
  return (
    <FormControl data-testid="quantity-select-wrapper" sx={{ minWidth: '90px' }}>
      <InputLabel id="quantity-select">{copyText.App.quantityAbbreviated}</InputLabel>
      <Select
        onChange={handleUpdateQuantity}
        id="quantity-select"
        type="number"
        label="Qty"
        sx={{ height: '40px' }}
        value={numItem}
      >
        {qtyValues.map((value) => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

QuantitySelect.propTypes = {
  updateQuantity: func.isRequired,
  numItem: number.isRequired,
};

export default QuantitySelect;
