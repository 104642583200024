import { React, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import productFiltersList from 'utils/productFiltersList';
import SkeletonGrid from 'views/Shop/components/SkeletonGrid/SkeletonGrid';
import ErrorPage from 'views/ErrorPage/ErrorPage';
import ProductCard from 'components/ProductCard/ProductCard';
import { copyText, lang } from 'language';
import { useShopProducts } from 'contexts';

import ShopHeader from '../../components/ShopHeader/ShopHeader';
import FilterBar from '../../components/FilterBar/FilterBar';
import ProductFilter from '../../Helpers/Filters/ProductFilter';

const Variants = () => {
  const { productId, categoryId } = useParams();
  const [allVariants, setAllVariants] = useState([]);
  const [variants, setVariants] = useState([]);
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState();
  const minVariants = 4;

  const shopProducts = useShopProducts();

  const getFilters = (currentProduct) => {
    const result = productFiltersList(currentProduct);
    setFilters(result);
  };

  const formatAttributes = (attributes) => {
    const resp = {};
    attributes.forEach((attr) => {
      resp[attr.name] = attr.value;
    });
    return resp;
  };

  const formatVariants = (_variants) => {
    const resp = _variants.map((variant) => {
      const attributes = formatAttributes(variant.attributes);
      return {
        ...variant,
        attributes,
      };
    });
    return resp;
  };

  const filterVariants = (selectedFilters) => {
    let selectedVariants = allVariants;
    selectedFilters.forEach((selectedFilter) => {
      if (selectedFilter.filterBy !== 'all') {
        selectedVariants = selectedVariants.filter((variant) => {
          if (variant.attributes?.title === selectedFilter.filterBy) {
            return true;
          }
          return false;
        });
      }
    });
    setVariants(selectedVariants);
  };

  const showFilterBar = () => {
    const show = allVariants.length > minVariants && filters.length > 0;
    return show;
  };

  const findProduct = (products, prodId) => {
    return products.find((item) => item.id === prodId);
  };

  const getVariants = async () => {
    if (shopProducts) {
      const products = await shopProducts[categoryId];

      if (products) {
        const item = findProduct(products, productId);

        if (item) {
          const fetchedVariants = ProductFilter.GetVariants(item);
          const formattedVariants = formatVariants(fetchedVariants);
          setAllVariants(formattedVariants);
          setVariants(formattedVariants);
          getFilters(item);
          setProduct(item);
          setLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    if (shopProducts) {
      getVariants();
    }
  }, [shopProducts]);

  if (!product && loading && !shopProducts) {
    return (
      <>
        <ShopHeader />
        <Grid container spacing={4} sx={{ justifyContent: 'space-evenly' }}>
          <SkeletonGrid numItems={6} />
        </Grid>
      </>
    );
  }

  if (!product && !loading) {
    return <ErrorPage error={copyText.Shop.Variants.error} />;
  }

  if (product) {
    return (
      <>
        <ShopHeader />
        <Grid>
          {showFilterBar() && (
            <FilterBar title="Filters" filters={filters} returnFilters={filterVariants} />
          )}
        </Grid>
        <Grid container spacing={4} sx={{ justifyContent: 'space-evenly' }}>
          {variants.map((variant) => (
            <Grid key={variant.id} item>
              <ProductCard
                imgUrl={variant.images[0]?.url}
                details={{
                  sku: variant.sku,
                  title: variant.name ? variant.name[lang] : variant.attributes.title,
                  price: variant.prices,
                  desc: variant.description || variant.attributes.title,
                }}
                id={variant.sku}
                productLink={`/category/${categoryId}/product/${productId}/variant/${variant.sku}`}
              />
            </Grid>
          ))}
        </Grid>
      </>
    );
  }
  return null;
};

export default Variants;
