import axios from 'axios';
import { FeedbackBody } from './interfaces/feedbackInterface';
import getAccessToken from 'utils/getAccessToken';
const sendFeedBack = async (feedbackForm: FeedbackBody) => {
  if (
    feedbackForm.title &&
    feedbackForm.comments &&
    feedbackForm.agent &&
    feedbackForm.storeName &&
    feedbackForm.webPage
  ) {
    const accessToken = getAccessToken();
    const body = {
      fields: {
        project: {
          key: 'MPOS',
        },
        summary: `FEEDBACK | ${feedbackForm.selectedFeedbackType} | ${feedbackForm.title}`,
        description: {
          type: 'doc',
          version: 1,
          content: [
            {
              type: 'paragraph',
              content: [
                {
                  text: `
                  From: ${feedbackForm.agent}, 
                  Store: ${feedbackForm.storeName}

                  Page: ${feedbackForm.webPage}
                  Type: ${feedbackForm.selectedFeedbackType} 
                  
                  Comments: ${feedbackForm.comments}
                  `,
                  type: 'text',
                },
              ],
            },
          ],
        },
        issuetype: {
          name: 'Story',
        },
      },
    };
    try {
      const uri = `${process.env.REACT_APP_PIM_BASE_URL}/feedback`;
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const result = await axios.post(uri, body, config);
      return result;
    } catch (error) {
      return error;
    }
  } else {
    throw new Error(
      'Feedback request requires a Page, Store, Agent, Title, Desription, and Comments',
    );
  }
};

export default {
  sendFeedBack,
};
