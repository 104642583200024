import React from 'react';
import { Box, Grid, ImageListItem, Typography } from '@mui/material';
import { shape, objectOf, string, number, arrayOf } from 'prop-types';
import toDollar from 'utils/toDollar';
import { lang } from 'language';

const ItemDetails = ({ itemDetails }) => {
  const { variant: variantData } = itemDetails;
  const variantTitle = variantData.attributes.filter((element) => {
    return element.name === 'title';
  });
  const { centAmount: price } = itemDetails.price.value;

  const getItemDiscount = (item) => {
    const discounts = JSON.parse(item.custom.fields.discounts_json);
    const nonCouponDiscount = discounts.reduce((acc, current) => {
      return acc + current.cent_amount;
    }, 0);
    return nonCouponDiscount;
  };
  const showDiscountNames = () => {
    const discountArray = JSON.parse(itemDetails.custom.fields.discounts_json);
    return discountArray.map((discount) => {
      if (discount.coupon_code) {
        return (
          <Typography key={discount.id} sx={{ color: 'darkAqua' }}>
            {`${discount.coupon_code}(-$${toDollar(discount.cent_amount)})`}
          </Typography>
        );
      }
      return (
        <Typography key={discount.id} sx={{ color: 'darkAqua' }}>
          {`${discount.display_name} (-$${toDollar(discount.cent_amount)})`}
        </Typography>
      );
    });
  };

  const showDiscounts = () => {
    const discountAmount = getItemDiscount(itemDetails);
    if (discountAmount) {
      return (
        <Grid container justifyContent="space-evenly">
          <Grid item>
            <Typography variant="subtitle1" sx={{ textDecoration: 'line-through' }}>
              ${toDollar(price + discountAmount)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" sx={{ color: 'darkAqua' }}>
              ${toDollar(price)}
            </Typography>
          </Grid>
          <Grid item>{showDiscountNames()}</Grid>
        </Grid>
      );
    }
    return <Typography variant="subtitle1">${toDollar(price)}</Typography>;
  };

  return (
    <Box backgroundColor="lightGrayBG">
      <Grid container columns={16} alignItems="center">
        <Grid item sm={3}>
          <ImageListItem>
            {/* SUNSET need to add this to production */}
            {/* <img src={variantData.images[0].url} alt={copyText.Orders.ItemDetails.alt} /> */}
          </ImageListItem>
        </Grid>
        <Grid item sm={5}>
          <Typography variant="h6" component="h1">
            {itemDetails.name[lang]}
          </Typography>
          <Typography variant="subtitle1">{variantTitle[0]?.value}</Typography>
        </Grid>
        <Grid item sm={1} p={2}>
          <Typography variant="subtitle1">x{itemDetails.quantity}</Typography>
        </Grid>
        <Grid item sm={7} p={2}>
          {showDiscounts()}
        </Grid>
      </Grid>
    </Box>
  );
};

ItemDetails.propTypes = {
  itemDetails: shape({
    name: objectOf(string),
    price: shape({
      value: shape({
        type: string,
        currencyCode: string,
        centAmount: number,
        fractionDigits: number,
      }),
    }),
    quantity: number,
    variant: shape({
      images: arrayOf(
        shape({
          dimensions: objectOf(number),
          label: string,
          url: string,
        }),
      ),
      attributes: arrayOf(shape({ name: string })),
    }),
  }).isRequired,
};

export default ItemDetails;
