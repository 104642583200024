import React from 'react';
import { arrayOf } from 'prop-types';
import { useSetSelectedOrder, useSetOrderPayment, useSelectedOrder } from 'contexts/orders';
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  Card,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { copyText } from 'language';

const OrderList = ({ orders }) => {
  const setOrderPayment = useSetOrderPayment();
  const setSelectedOrder = useSetSelectedOrder();
  const selectedOrder = useSelectedOrder();
  const handleListItemClick = (order) => {
    setOrderPayment(null);
    setSelectedOrder(order);
  };

  return (
    <>
      <Typography variant="h5" component="h1">
        {copyText.Orders.OrderList.orders}
      </Typography>
      <Divider />
      {orders.length === 0 && (
        <Box
          alignItems="center"
          height="50vh"
          justifyContent="center"
          text-align="center"
          display="flex"
        >
          {copyText.Orders.OrderList.noOrdersFound}
        </Box>
      )}
      {orders.length > 0 && (
        <List
          id="OrdersList"
          sx={{
            position: 'relative',
            overflow: 'scroll',
            maxHeight: '55vh',
            mb: 2,
          }}
        >
          {orders.map((order) => {
            return (
              <Card variant="outlined" sx={{ mb: 1 }} key={order.orderNumber}>
                <ListItemButton
                  selected={selectedOrder?.id === order.id}
                  onClick={() => {
                    handleListItemClick(order);
                  }}
                >
                  <ListItemText
                    primary={`Order: ${order.orderNumber}`}
                    secondary={`${order.shippingAddress?.firstName} ${order.shippingAddress?.lastName}`}
                  />
                  <ListItemText primary={new Date(order.createdAt).toLocaleDateString()} />

                  <ListItemIcon>
                    <Typography ml={2} variant="subtitle2">
                      {copyText.Orders.OrderList.details}
                    </Typography>
                    <ChevronRightIcon />
                  </ListItemIcon>
                </ListItemButton>
              </Card>
            );
          })}
        </List>
      )}
    </>
  );
};

OrderList.propTypes = {
  orders: arrayOf(Object),
};

OrderList.defaultProps = {
  orders: [],
};

export default OrderList;
