import { useState, useEffect } from 'react';
import { Button, ListItemIcon, MenuItem, Select } from '@mui/material';
import cloverUtils from 'utils/clover';
import { PointOfSale } from '@mui/icons-material';
import { copyText } from 'language';
import clover from 'dataAccess/api/clover.ts';
import { useOktaAuth } from '@okta/okta-react';

const DeviceSelect = () => {
  const [deviceList, setDeviceList] = useState([]);
  const [cloverToken, setCloverToken] = useState(false);
  const { oktaAuth } = useOktaAuth();
  const [selectedDevice, setSelectedDevice] = useState('select');
  const name = oktaAuth.authStateManager?._authState?.idToken?.claims?.name;
  const handleDeviceSelect = (e) => {
    setSelectedDevice(e.target.value);
    cloverUtils.setSessionCloverDevice(e.target.value);
    if (e.target.value !== 'select') {
      try {
        cloverUtils.displayMessage(e.target.value, name);
        setTimeout(() => {
          clover.displayWelcome();
        }, 5000);
      } catch (error) {
        alert('could not connect to device');
      }
    }
  };
  const getAvailableDevices = async () => {
    const result = await cloverUtils.getDevices();
    setDeviceList(result);
    const savedDevice = cloverUtils.getSessionCloverDevice();
    if (savedDevice) {
      setSelectedDevice(savedDevice);
    } else if (!savedDevice || result.length === 0) {
      setSelectedDevice('select');
    }
  };

  const checkCloverToken = () => {
    const token = cloverUtils.getCloverAccessToken();
    if (token) {
      setCloverToken(true);
      return true;
    }
    setCloverToken(false);
    return false;
  };

  const redirectToClover = () => {
    clover.cloverAuthorization();
  };

  useEffect(() => {
    const result = checkCloverToken();
    if (result) {
      getAvailableDevices();
    }
  }, []);

  return (
    <MenuItem>
      <ListItemIcon>
        <PointOfSale />
      </ListItemIcon>
      {cloverToken && (
        <Select
          value={selectedDevice}
          onChange={handleDeviceSelect}
          variant="standard"
          disableUnderline
        >
          {deviceList.map((device) => {
            return (
              <MenuItem key={device.id} value={device.serial}>
                {device.serial}
              </MenuItem>
            );
          })}
          <MenuItem value="select">{copyText.Terminal.noDeviceSelected}</MenuItem>
        </Select>
      )}
      {!cloverToken && (
        <Button onClick={redirectToClover} sx={{ background: 'none' }}>
          Connect To Clover
        </Button>
      )}
    </MenuItem>
  );
};
export default DeviceSelect;
