import React from 'react';
import { Box, Typography, Grid, ImageListItem, CircularProgress } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import PaymentIcon from '@mui/icons-material/Payment';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { useSelectedOrder, useOrderPayment } from 'contexts/orders';
import VisaIcon from 'media/images/visaBlue.png';
import MastercardIcon from 'media/images/mc_symbol.svg';
import DiscoverLogo from 'media/images/discoverLogo.png';
import { copyText } from 'language';

const CustomerDetails = () => {
  const selectedOrder = useSelectedOrder();
  const orderPayment = useOrderPayment();
  const { shippingAddress } = selectedOrder;

  const getCardType = (brand) => {
    let card;
    if (brand === 'visa') {
      card = VisaIcon;
    } else if (brand === 'mastercard') {
      card = MastercardIcon;
    } else if (brand === 'discover') {
      card = DiscoverLogo;
    } else {
      card = 'american express';
    }
    return card;
  };

  const showPayment = () => {
    if (orderPayment) {
      return orderPayment.map((payment) => {
        if (
          payment.payment.paymentMethodInfo.method !== 'GIFT_CARD' &&
          payment.payment.custom?.fields?.maskedCard
        ) {
          return (
            <Grid key={payment.payment.id} container alignItems="center">
              <Grid item>
                <ImageListItem sx={{ width: 60, mr: 1 }}>
                  <img
                    src={getCardType(payment.payment.custom.fields?.cardBrand)}
                    alt={copyText.Orders.CustomerDetails.creditCard.alt}
                  />
                </ImageListItem>
              </Grid>
              <Grid item>
                <Typography>
                  {copyText.Orders.CustomerDetails.endingIn}
                  {payment.payment.custom?.fields?.maskedCard}
                </Typography>
              </Grid>
            </Grid>
          );
        }
        if (payment.payment.paymentMethodInfo.method !== 'GIFT_CARD') {
          return (
            <Grid key={payment.payment.id} container>
              <Grid item>
                <Typography>
                  {payment.payment.paymentMethodInfo.method.replace('_', ' ')}
                </Typography>
              </Grid>
            </Grid>
          );
        }
        return null;
      });
    }

    return <CircularProgress />;
  };

  return (
    <Box sx={{ backgroundColor: 'lightGrayBG', mt: 2 }}>
      <Grid container columns={16} mb={1} p={2}>
        <Grid item xs={16} sm={9} alignItems="center">
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <PersonIcon fontSize="large" />
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {`${shippingAddress.firstName} ${shippingAddress.lastName}`}
              </Typography>
              <Typography variant="subtitle2">{selectedOrder.customerEmail}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={16} sm={7} alignItems="center">
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <PhoneIcon />
            </Grid>
            <Grid item>
              <Typography>{copyText.Orders.CustomerDetails.phone}</Typography>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {shippingAddress.phone}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="center" direction="row" p={2} mb={2}>
        <Grid item>
          <PaymentIcon fontSize="large" sx={{ mr: 1 }} />
        </Grid>
        <Grid item sm={10}>
          <Typography variant="subtitle1">
            {copyText.Orders.CustomerDetails.paymentMethod}
          </Typography>
          {showPayment()}
        </Grid>
      </Grid>
      <Grid container alignItems="center" p={2} mb={1}>
        <Grid item>
          <LocalShippingIcon fontSize="large" sx={{ mr: 1 }} />
        </Grid>
        <Grid item>
          <Box>
            <Typography>{copyText.Orders.CustomerDetails.shippingAddress}</Typography>
            <Typography fontWeight="bold">
              {`${shippingAddress.streetName} ${shippingAddress.city}, ${shippingAddress.state} ${shippingAddress.postalCode}`}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomerDetails;
