import React, { useState, useEffect } from 'react';
import { Button, Dialog } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { copyText } from 'language';
import RefundService from 'dataAccess/api/refunds/refunds.ts';
import { useSelectedOrder } from 'contexts/orders';
import toDollar from 'utils/toDollar';
import RefundPreview from './RefundPreview';
import InitialForm from './InitialForm';
import LoadingModal from './LoadingModal';
import ResultModal from './ResultModal';

const RefundModal = () => {
  const selectedOrder = useSelectedOrder();
  const { oktaAuth } = useOktaAuth();
  const [refundToggle, setToggleRefund] = useState(false);
  const [view, setView] = useState('form');
  const [payments, setPayments] = useState();
  const [refundInfo, setRefundInfo] = useState({
    agentName: '',
    refundAmount: '',
    refundReason: '',
    orderId: '',
    manualRefund: [],
    result: {},
    error: false,
  });
  const views = {
    preview: 'preview',
    form: 'form',
    loading: 'loading',
    result: 'result',
  };

  const changeView = (newView) => {
    setView(newView);
  };
  const openModal = () => {
    setView('form');
    setToggleRefund(true);
  };
  const closeModal = () => {
    setToggleRefund(false);
    setRefundInfo((prev) => ({
      ...prev,
      agentName: '',
      refundAmount: '',
      refundReason: '',
      orderId: '',
      manualrefund: [],
      error: false,
      // result: {},
    }));
  };

  const updateInfo = (e) => {
    const value = e.target.value;
    const key = e.target.getAttribute('name');

    setRefundInfo((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const updateResult = (result) => {
    setRefundInfo((prev) => ({
      ...prev,
      result: { ...result },
    }));
    changeView(views.result);
  };
  const triggerAmountError = () => {
    setRefundInfo((prev) => ({
      ...prev,
      refundAmount: '',
      error: true,
    }));
    // setTimeout(() => {
    //   setRefundInfo((prev) => ({
    //     ...prev,
    //     error: false,
    //   }));
    // }, 5000);
  };

  const validateAmount = () => {
    const totalAmount = toDollar(selectedOrder.totalPrice.centAmount);
    if (refundInfo.refundAmount) {
      if (+refundInfo.refundAmount > 0 && +refundInfo.refundAmount <= totalAmount) {
        return true;
      }
    }
    return false;
  };

  const refundPreview = async () => {
    if (validateAmount() && selectedOrder.id) {
      changeView(views.loading);
      const input = {
        orderId: selectedOrder.id,
        refundAmount: refundInfo.refundAmount,
        reason: refundInfo.refundReason,
      };

      const result = await RefundService.refundPreview(input);
      if (result?.result) {
        setPayments(result?.result?.payments);
        changeView(views.preview);
      } else {
        changeView(views.result);
      }
    } else {
      triggerAmountError();
    }
  };

  const userData = () => {
    const name = oktaAuth.authStateManager?._authState?.idToken?.claims?.name;
    if (name) {
      setRefundInfo((prev) => ({
        ...prev,
        agentName: name,
      }));
    }
  };

  const checkView = () => {
    if (view === views.form) {
      return (
        <InitialForm
          refundInfo={refundInfo}
          updateInfo={updateInfo}
          closeModal={closeModal}
          refundPreview={refundPreview}
        />
      );
    }
    if (view === views.preview) {
      return (
        <RefundPreview
          closeModal={closeModal}
          payments={payments}
          refundInfo={refundInfo}
          selectedOrder={selectedOrder}
          updateResult={updateResult}
          changeView={changeView}
          views={views}
        />
      );
    }

    if (view === views.loading) {
      return <LoadingModal />;
    }

    if (view === views.result) {
      return (
        <ResultModal
          refundInfo={refundInfo}
          closeModal={closeModal}
          changeView={changeView}
          views={views}
        />
      );
    }

    return null;
  };

  useEffect(() => {
    userData();
  }, [oktaAuth.authStateManager._authState]);

  return (
    <>
      <Button variant="outlined" onClick={openModal}>
        {copyText.Orders.RefundModal.refund}
      </Button>
      <Dialog
        fullWidth
        open={refundToggle}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {checkView()}
      </Dialog>
    </>
  );
};

export default RefundModal;
