import axios from 'axios';
import getAccessToken from 'utils/getAccessToken';
import { copyText } from 'language';

/**
 * Partial refund - Returns a givin amount to the order given.
 * @param orderId the Id of the order to be refunded.
 * @param refundAmount the dollar amount of the refund.
 * @param reason the reason for the refund.
 * @returns The result of the refund.
 */

interface RefundInterface {
  orderId: string;
  refundAmount: number;
  reason: string;
}

interface refund {
  payment_id: string;
  refund: number;
}
interface ManualInterface {
  orderId: string;
  manualRefunds: refund[];
  reason: string;
}

const automaticRefund = async (params: RefundInterface) => {
  const { orderId, refundAmount, reason } = params;
  try {
    const accessToken = getAccessToken();
    const hasRequiredParams = orderId && refundAmount;
    if (hasRequiredParams) {
      const uri = `${process.env.REACT_APP_PIM_BASE_URL}/refund/automatic`;
      const reqBody = { orderId, amount: refundAmount, reason };
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const payload = JSON.stringify(reqBody);
      const result = await axios.post(uri, payload, config);
      return result.data;
    }
    throw Error(copyText.Services.autoRefundError);
  } catch (error) {
    return copyText.Services.refundFail;
  }
};

const manualRefund = async (params: ManualInterface) => {
  const { orderId, manualRefunds, reason } = params;
  try {
    const accessToken = getAccessToken();
    const hasRequiredParams = orderId && manualRefund;
    if (hasRequiredParams) {
      const uri = `${process.env.REACT_APP_PIM_BASE_URL}/refund/manual`;
      const reqBody = { orderId, manualRefunds, reason };
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const payload = JSON.stringify(reqBody);
      const result = await axios.post(uri, payload, config);
      return result.data;
    }
    throw Error(copyText.Services.autoRefundError);
  } catch (error) {
    return copyText.Services.refundFail;
  }
};

const refundPreview = async (params: RefundInterface) => {
  const { orderId, refundAmount, reason } = params;
  try {
    const accessToken = getAccessToken();
    const hasRequiredParams = orderId && refundAmount;
    if (hasRequiredParams) {
      const uri = `${process.env.REACT_APP_PIM_BASE_URL}/refund/preview`;
      const reqBody = { orderId, amount: refundAmount, reason };
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const payload = JSON.stringify(reqBody);
      const result = await axios.post(uri, payload, config);
      return result.data;
    }
    throw Error(copyText.Services.refundPreviewError);
  } catch (error) {
    return copyText.Services.refundFail;
  }
};

export default {
  automaticRefund,
  refundPreview,
  manualRefund,
};
