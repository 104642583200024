import {
  Drawer,
  List,
  ListItem,
  Box,
  Badge,
  Typography,
  Divider,
  Button,
  Link,
  Grid,
} from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ChevronRight } from '@mui/icons-material';
import EmptyCart from 'views/Cart/Components/LeftColumn/components/EmptyCart';
import copyText from 'language/enUS';
import { useCart } from 'contexts';
import CartCard from 'components/CartCard/CartCard';

const CartDrawer = () => {
  const [count, setCount] = useState(0);
  const cart = useCart();
  const [openDrawer, setOpenDrawer] = useState(false);
  useEffect(() => {
    setCount(cart?.totalLineItemQuantity || 0);
  }, [cart]);
  return (
    <>
      <Box>
        <Badge badgeContent={count} color="primary">
          <ShoppingCartIcon onClick={() => setOpenDrawer(true)} fontSize="large" />
        </Badge>
      </Box>
      <Drawer anchor="right" open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <section role="main">
          <Box sx={{ width: { xs: '100vw', sm: '60vw', md: '50vw', lg: '30vw' } }} height="100vh">
            <Grid
              container
              alignItems="center"
              display="flex"
              flexDirection="row"
              marginTop={3}
              marginBottom={2}
            >
              <Grid
                item
                columns={4}
                marginLeft={3}
                marginRight={3}
                display="flex"
                justifyContent="center"
              >
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  onClick={() => setOpenDrawer(false)}
                  aria-label={copyText.Cart.CartTools.closeCartPreview}
                >
                  <ChevronRight />
                </Button>
              </Grid>
              <Grid item columns={12}>
                <Typography
                  padding={1}
                  display="flex"
                  justifyContent="center"
                  color="primary"
                  variant="h5"
                  component="h1"
                  alignItems="center"
                >
                  {copyText.Cart.CartTools.cartPreview}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Box
              sx={{
                position: 'relative',
                overflow: 'scroll',
                msOverflowStyle: 'none',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                height: '70vh',
                mb: 4,
              }}
            >
              {cart?.lineItems?.length > 0 ? (
                <List sx={{ mr: 3 }}>
                  {cart?.lineItems.map((lineItem) => {
                    return (
                      <ListItem key={lineItem.id}>
                        <CartCard productData={lineItem} showPrice showDelete />
                      </ListItem>
                    );
                  })}
                </List>
              ) : (
                <EmptyCart />
              )}
            </Box>
            <Divider />
            <Box textAlign="center" padding={3} height="15vh">
              <Link underline="none" color="white" component={RouterLink} to="/cart">
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  sx={{
                    textTransform: 'none',
                    height: 56,
                    maxWidth: '35vw',
                  }}
                  onClick={() => setOpenDrawer(false)}
                >
                  <Typography justifyContent="center" variant="p" component="span" color="white">
                    {copyText.Cart.CartTools.viewCart}
                  </Typography>
                </Button>
              </Link>
            </Box>
          </Box>
        </section>
      </Drawer>
    </>
  );
};

export default CartDrawer;
