import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import createCart from 'utils/createCart';
import { Grid, Typography } from '@mui/material';
import { copyText } from 'language';
import { addCartToHistory } from 'utils/cartHistory';
import { useSetCart } from 'contexts';
import TextLoader from 'components/TextLoader';

const ClearCart = () => {
  const setCart = useSetCart();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const clearCart = async () => {
    try {
      setLoading(true);
      const newCart = await createCart();
      setCart(newCart);
      window.sessionStorage.removeItem('shipping-methods');
      addCartToHistory(newCart);
      setOpen(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid
        sx={{ mt: 4, mb: 4 }}
        container
        columns={16}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={8} display="flex" justifyContent="center">
          <Typography fontSize={13}>{copyText.Cart.CheckoutButtons.startOver}</Typography>
        </Grid>
        <Grid item xs={12} sm={8} display="flex" justifyContent="center">
          <Button variant="outlined" onClick={handleClickOpen}>
            <Typography variant="p" component="span">
              {copyText.Cart.CartTools.clearCart}
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ backgroundColor: 'lightGrayBG', mb: 4 }} id="alert-dialog-title">
          {copyText.Cart.CartTools.verifyClearCart}
        </DialogTitle>
        <DialogContent sx={{ mb: 4 }}>
          <DialogContentText id="alert-dialog-description">
            {copyText.Cart.CartTools.clearCartDialog}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2, backgroundColor: 'lightGrayBG' }}>
          <Button sx={{ m: 1 }} variant="outlined" onClick={handleClose}>
            <Typography sx={{ pr: 1, pl: 1, pt: 1, pb: 1 }} variant="h3" component="span">
              {copyText.App.cancel}
            </Typography>
          </Button>
          <Button sx={{ m: 1 }} disabled={loading} variant="contained" onClick={clearCart}>
            <Typography
              sx={{ pr: 1, pl: 1, pt: 1, pb: 1 }}
              color="white"
              variant="h3"
              component="span"
            >
              <TextLoader text={copyText.Cart.CartTools.clearCart} loading={loading} size={14} />
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ClearCart;
