import axios from 'axios';
import getAccessToken from 'utils/getAccessToken';
import {
  BasicAddress,
  GetAutocompleteAddressParams,
  RemoveAddressParams,
  UpdateAddressParams,
} from './interfaces/cartEndpointInterfaces';

/**
 * validateShippingAddress - Takes in a shipping address and returns validation details about it
 * @param params The address to validate
 * @returns A validated address object or undefined
 * @link https://onpurple.github.io/carts-ms/#/paths/~1address~1validate/post
 */
const validateShippingAddress = (params: BasicAddress) => {
  const oktaToken = getAccessToken();
  const hasRequiredParams =
    !!params &&
    !!params.firstName &&
    !!params.lastName &&
    !!params.streetAddress &&
    !!params.postalCode &&
    !!params.city &&
    !!params.state &&
    !!params.country &&
    !!params.email;

  if (hasRequiredParams) {
    const reqBody = params;
    // const oktaToken = Fetch_Promise.Get_Okta_Token();
    const uri = `${process.env.REACT_APP_MS_URL}/carts/address/validate`;
    const config = {
      headers: {
        role: 'agent',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oktaToken}`,
      },
    };
    return axios.post(uri, JSON.stringify(reqBody), config);

    // TODO: Verify this path works for us and doesnt require active cart session
    // return Fetch_Promise.Post(path, reqBody, oktaToken);
  }

  throw new Error(
    'Validating address requires: firstName, lastName, streetAddress, postalCode, city, state, country, email',
  );
};

/**
 * getAutocompleteAddress - Autocompletes on an adress being entered
 * @param params {input, countryCode, sessionToken}
 * @returns an array of address objects
 * @link https://onpurple.github.io/carts-ms/#/paths/~1address~1autocomplete/get
 */
const getAutocompleteAddress = (params: GetAutocompleteAddressParams, oktaToken?: string) => {
  const hasRequiredParams = !!params && !!params.input && !!params.sessionToken;
  const hasOktaToken = !!oktaToken;
  if (!hasOktaToken) {
    const tokenStorage = localStorage.getItem('okta-token-storage');
    oktaToken = tokenStorage && JSON.parse(tokenStorage).accessToken.accessToken;
  }

  if (hasRequiredParams) {
    const queryParams = {
      ...params,
      countryCode: params.countryCode || 'us',
    };

    // const query = Fetch_Promise.Encode_Query_Params(queryParams);
    // const path = `${process.env.REACT_APP_MS_URL}/carts/address/autocomplete?${query}`;
    const uri = `${process.env.REACT_APP_MS_URL}/carts/address/autocomplete`;
    let config = {
      headers: {
        role: 'agent',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oktaToken}`,
      },
      params: {},
    };
    config.params = queryParams;
    return axios.get(uri, config);

    // TODO: Verify this path works for us and not sessions
    // TODO: Convert to Fetch_Promise
    // return Fetch.Get(path);
  }

  throw new Error('Getting autocomplete address requires: input, sessionToken');
};

const updateAddress = (params: UpdateAddressParams, oktaToken?: string) => {
  // "type" should be "shipping" or "billing" and should be explicitly called separately if both are needed.
  /**
   * DRAFT: https://onpurple.github.io/carts-ms/#/paths/~1draft-cart~1{cartId}~1shipping-address/post
   * https://onpurple.github.io/carts-ms/#/paths/~1{cartId}~1shipping-address/post
   * DRAFT: https://onpurple.github.io/carts-ms/#/paths/~1draft-cart~1{cartId}~1billing-address/post
   * https://onpurple.github.io/carts-ms/#/paths/~1{cartId}~1billing-address/post
   *
   */
  const hasRequiredParams = params?.cartId && params?.type && params?.payload;

  const hasOktaToken = !!oktaToken;
  if (!hasOktaToken) {
    const tokenStorage = localStorage.getItem('okta-token-storage');
    oktaToken = tokenStorage && JSON.parse(tokenStorage).accessToken.accessToken;
  }

  if (hasRequiredParams) {
    const reqBody = params.payload;
    if (params?.type === 'shipping') {
      const uri = '#updateShippingAddress';
      // return Fetch.Post(url, reqBody);
      let config = {
        headers: {
          role: 'agent',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${oktaToken}`,
        },
      };
      return axios.post(uri, JSON.stringify(reqBody), config);
    }
    if (params?.type === 'billing') {
      const uri = '#updateBillingAddress';
      // return Fetch.Post(url, reqBody);
      let config = {
        headers: {
          role: 'agent',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${oktaToken}`,
        },
      };
      return axios.post(uri, JSON.stringify(reqBody), config);
    }
    throw new Error('Update address requires type to be: shipping or billing');
  }
  throw new Error('Update address requires: cartId, type, BasicAddress');
};

const removeAddress = (params: RemoveAddressParams, oktaToken?: string) => {
  // "type" should be "shipping" or "billing" and should be explicitly called separately if both are needed.
  /**
   * https://onpurple.github.io/carts-ms/#/paths/~1{cartId}~1shipping-address/delete
   * https://onpurple.github.io/carts-ms/#/paths/~1{cartId}~1billing-address/delete
   */
  const hasRequiredParams = params?.cartId && params?.type;
  const hasOktaToken = !!oktaToken;
  if (!hasOktaToken) {
    const tokenStorage = localStorage.getItem('okta-token-storage');
    oktaToken = tokenStorage && JSON.parse(tokenStorage).accessToken.accessToken;
  }
  if (hasRequiredParams) {
    const { cartId, type } = params;
    const shippingUrl = `#remove_shipping_address/${cartId}`;
    const billingUrl = `#remove_billing_address/${cartId}`;
    const uri = type === 'shipping' ? shippingUrl : billingUrl;

    let config = {
      headers: {
        role: 'agent',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oktaToken}`,
      },
    };
    return axios.delete(uri, config);

    // return Fetch.Delete(url);
  }
  throw new Error('Removing address from cart requires: cartId, type');
};

/**
 * setShippingAddress - Adds a shipping address to the cart
 * @param params BasicAddress
 * @param cartId the CT id of the cart
 * @returns an updated Cart object or undefined
 * @link https://onpurple.github.io/carts-ms/#/paths/~1{cartId}~1shipping-address/post
 */
const setShippingAddress = async (cartId: string, params: BasicAddress) => {
  const oktaToken = getAccessToken();
  const hasRequiredParams =
    !!params &&
    !!params.firstName &&
    !!params.lastName &&
    !!params.streetAddress &&
    !!params.postalCode &&
    !!params.city &&
    !!params.state &&
    !!params.country &&
    !!params.email &&
    !!cartId;
  if (hasRequiredParams) {
    const validateShipping = await validateShippingAddress(params);
    if (validateShipping.data.score !== '0') {
      const req = params;
      // const oktaToken = Fetch_Promise.Get_Okta_Token();
      const uri = `${process.env.REACT_APP_MS_URL}/carts/${cartId}/shipping-address`;
      const config = {
        headers: {
          role: 'agent',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${oktaToken}`,
        },
      };
      const reqBody = JSON.stringify(req);
      const response = await axios.post(uri, reqBody, config);
      return response.data;
    }
    throw new Error('INVALID_ADDRESS');
  }
  throw new Error(
    'Set shipping address requires: firstName, lastName, streetAddress, postalCode, city, state, country, email, cartId',
  );
};

/**
 * removeShippingAddress - Removes the shipping address object from the cart
 * @param cartId the ct  id of the cart
 * @returns an updated cart or undefined
 * @link https://onpurple.github.io/carts-ms/#/paths/~1{cartId}~1shipping-address/delete
 */
const removeShippingAddress = async (cartId: string, oktaToken?: string) => {
  const hasRequiredParams = !!cartId;

  const hasOktaToken = !!oktaToken;
  if (!hasOktaToken) {
    const tokenStorage = localStorage.getItem('okta-token-storage');
    oktaToken = tokenStorage && JSON.parse(tokenStorage).accessToken.accessToken;
  }

  if (hasRequiredParams) {
    // const oktaToken = Fetch_Promise.Get_Okta_Token();
    const uri = `${process.env.REACT_APP_MS_URL}/carts/${cartId}/shipping-address`;
    let config = {
      headers: {
        role: 'agent',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oktaToken}`,
      },
    };
    let response = await axios.delete(uri, config);

    return response;
  }

  throw new Error('Remove shipping address requires: cartId');
};

/**
 * setBillingAddress - Adds a billing address to a cart
 * @param params an address object
 * @param cartId the ct id for the cart
 * @returns an updated cart or undefined
 * @link https://onpurple.github.io/carts-ms/#/paths/~1{cartId}~1billing-address/post
 */
const setBillingAddress = async (params: BasicAddress, cartId: string, oktaToken?: string) => {
  const hasRequiredParams =
    !!params &&
    !!params.firstName &&
    !!params.lastName &&
    !!params.streetAddress &&
    !!params.postalCode &&
    !!params.city &&
    !!params.state &&
    !!params.country &&
    !!params.email &&
    !!cartId;

  const hasOktaToken = !!oktaToken;
  if (!hasOktaToken) {
    const tokenStorage = localStorage.getItem('okta-token-storage');
    oktaToken = tokenStorage && JSON.parse(tokenStorage).accessToken.accessToken;
  }

  if (hasRequiredParams) {
    const reqBody = params;
    // const oktaToken = Fetch_Promise.Get_Okta_Token();
    const uri = `${process.env.REACT_APP_MS_URL}/carts/${cartId}/billing-address`;
    let config = {
      headers: {
        role: 'agent',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oktaToken}`,
      },
    };
    let response = await axios.post(uri, JSON.stringify(reqBody), config);

    return response;
  }

  throw new Error(
    'Set billing address requires: firstName, lastName, streetAddress, postalCode, city, state, country, email, cartId',
  );
};

/**
 * removeBillingAddress - Removes the billing address object from the cart
 * @param cartId the CT Id of the cart
 * @returns An updated cart or undefined
 * @link https://onpurple.github.io/carts-ms/#/paths/~1{cartId}~1billing-address/delete
 */
const removeBillingAddress = async (cartId: string, oktaToken?: string) => {
  const hasRequiredParams = !!cartId;

  const hasOktaToken = !!oktaToken;
  if (!hasOktaToken) {
    const tokenStorage = localStorage.getItem('okta-token-storage');
    oktaToken = tokenStorage && JSON.parse(tokenStorage).accessToken.accessToken;
  }

  if (hasRequiredParams) {
    // const oktaToken = Fetch_Promise.Get_Okta_Token();
    const uri = `${process.env.REACT_APP_MS_URL}/carts/${cartId}/billing-address`;
    let config = {
      headers: {
        role: 'agent',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oktaToken}`,
      },
    };
    let response = await axios.delete(uri, config);

    return response;
  }

  throw new Error('Remove billing address requires: cartId');
};

export default {
  validateShippingAddress,
  getAutocompleteAddress,
  removeBillingAddress,
  setBillingAddress,
  removeShippingAddress,
  setShippingAddress,
  updateAddress,
  removeAddress,
};
